import React, { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Profile from "../images/login1.png";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import css
import { auth } from "../firebase"; // Assuming you have firebase authentication setup

function ChangePassword() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    newpassword: "",
    confirmpassword: "",
  });
  const { email, newpassword, confirmpassword } = formData;
  const navigate = useNavigate();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (newpassword !== confirmpassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent. Check your inbox.', {
        autoClose: 5000,
      });
      navigate("/"); // Redirect to home or login page
    } catch (error) {
      toast.error("Failed to send password reset email. Please try again later.");
      console.error("Error sending password reset email:", error);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container_password">
        <div className="form-container_password">
          <img src={Profile} alt="Profile" className="profile-img" />
          <h1 className="text-3xl text-center mt-6 font-bold pb-8 fontinter">Reset Password</h1>
          <form onSubmit={onSubmit}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Enter your email"
              className="searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              required
            />
            <div className="relative mb-6">
              <input
                type={showNewPassword ? "text" : "password"}
                id="newpassword"
                value={newpassword}
                onChange={onChange}
                placeholder="New Password"
                className="searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
              <div className="absolute right-3 top-3 text-xl cursor-pointer">
                {showNewPassword ? (
                  <AiFillEyeInvisible onClick={() => setShowNewPassword(false)} />
                ) : (
                  <AiFillEye onClick={() => setShowNewPassword(true)} />
                )}
              </div>
            </div>
            <div className="relative mb-6">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmpassword"
                value={confirmpassword}
                onChange={onChange}
                placeholder="Confirm Password"
                className="searchbox w-full text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
              <div className="absolute right-3 top-3 text-xl cursor-pointer">
                {showConfirmPassword ? (
                  <AiFillEyeInvisible onClick={() => setShowConfirmPassword(false)} />
                ) : (
                  <AiFillEye onClick={() => setShowConfirmPassword(true)} />
                )}
              </div>
            </div>
            <button style={{marginTop: "2%"}}
              className="w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword;
