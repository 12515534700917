import React from 'react'

export const IndividualProduct2 = ({individualProduct2}) => {
  return (
<div className='products-container '>
  <div className='product-card object-cover hover:scale-105 transition-scale duration-200 ease-in'>
    <div className='product-img'>
    <img src={individualProduct2.url} alt="product-img" /></div>
        <div className='product-name'>{individualProduct2.fabrictype}</div>
         <p className='product-price'>${individualProduct2.Price}</p>
    </div>
    </div>
  )
}