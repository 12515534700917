import { useState } from "react";
import React from 'react';
import "../admin_module/Adminsignin.css"
import { AiFillEyeInvisible, AiFillEye, AiOutlineUser } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Ensure you import your Firestore instance
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import css
import globalObject from "../../components/global"
import Profile from "../../images/admin logo.png"

export default function SignIn() {

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check user role from Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists() && userDoc.data().role === "admin") {
        // Store user email in global object
        globalObject.userId = email;
        globalObject.userValidate = true;

        // Success message and navigate
        toast.success('Signed in successfully!', {
          onClose: () => navigate("/admin/AdminDashboard"),
          autoClose: 500,
        });
      } else {
        toast.error("Access denied. Admins only.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        globalObject.userValidate = false;
      }
    } catch (error) {
      toast.error("Email or Password is Invalid", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  }
  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container_signin">
        <div className="form-container_signin">
          <img src={Profile} alt="Profile" className="profile-admin-img" />
          <h1 className="text-3xl text-center ml-4 font-bold pb-8 fontinter">Administrator</h1>
          <form onSubmit={onSubmit}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Email address"
              className="searchbox mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
            />
            <div className="relative mb-6">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder="Password"
                className="searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
              {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>
            <button style={{ marginTop: "2%" }}
              className="w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              Sign in
            </button>
            <div style={{ marginTop: "4%", display: 'flex', justifyContent: 'space-between' }}>
              <Link to='/admin/Adminforgotpassword' className="link" style={{ color: "blue", textDecoration: "none" }}>
                Forgot Password ?
              </Link>
              {/* <Link to='/admin/Adminsignup' className="link" style={{ color: "blue", textDecoration: "none" }}>
                Create New Account
              </Link> */}
            </div>
          </form>
        </div>
        <Link to='/' className="user-login-link" style={{ position: 'absolute', top: '10px', right: '10px', color: 'blue', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <AiOutlineUser className="user-login-icon" style={{ marginRight: '5px', fontSize: '50px' }} />
          User
        </Link>
      </div>
    </React.Fragment>
  );
}