import React, { useState } from "react";
import "./Signup.css"
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import Profile from "../images/login1.png";
import { useNavigate } from "react-router";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // User creation successful
      const user = userCredential.user;
      await setDoc(doc(db, "pendingUsers", user.uid), {
        email: user.email,
        role: "user",
        approved: false
      });
      toast.success('User created successfully! Pending admin approval.', {
        onClose: () => navigate("/"),
        autoClose: 2000,
      });
    } catch (error) {
      setError(error.message);
      console.error("Error creating user:", error);
      toast.error(error.message);
    }
  };
  
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="container_signup">
        <div className="form-container_signup">
          <img src={Profile} alt="Profile" className="profile-img" />
          <h1 className="text-3xl text-center mt-6 ml-3 font-bold pb-8 fontinter">
            New User
          </h1>
          <form onSubmit={handleSignUp}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="  searchbox mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
            />
            <div className="relative mb-6">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className=" searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
              {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword(true)}
                />
              )}
              {/* {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : ( 
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )} */}
            </div>
            {/* <div className="flex justify-between whitespace-nowrap text-sm sm:text-lg">
              
              <p>
                <Link
                  to="/forgot-password"
                  className="text-blue-600 hover:text-blue-800 transition duration-200 ease-in-out "
                >
                  Forgot password?
                </Link>
              </p>
            </div> */}
            <button
              
              className=" w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              Registering for Access
            </button>
            <div style={{marginTop: "4%", display: 'flex', justifyContent: 'space-between'}}>
               <Link to='/Forgotpassword' className="link" style={{color:"blue", textDecoration:"none"}}>
               Forgot Password ?
               </Link>
               <Link to='/' className="link" style={{color:"blue", textDecoration:"none"}}>
               Already have an Account ?
               </Link>
               </div>
          </form>
        </div>
        <Link to='/admin/Adminsignin' className="admin-login-link" style={{ position: 'absolute', top: '10px', right: '10px', color: 'blue', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <RiAdminLine className="admin-login-icon" style={{ marginRight: '5px', fontSize: '50px' }} />
          Admin
        </Link>
      </div>
    </React.Fragment>
  );
}
