import React, { useState } from "react";
// import "./AdminforgotPassword.css"
import Profile from "../../images/admin logo.png"
import { useNavigate } from "react-router";
// import firebase from '../firebase'
// import globalObject from "../components/global";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth, db, fs } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";

function ForgotPassword() {
  // const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const { email } = formData;
  const navigate = useNavigate();
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      // Fetch the user document from Firestore using the provided email address
      const querySnapshot = await fs.collection("users").where("email", "==", email).get();
  
      if (querySnapshot.empty) {
        // If no user found with the provided email, show an error toast
        toast.error("Email not found. Please enter a valid admin email.");
        return;
      }
  
      // Check if the user has admin role
      const userDoc = querySnapshot.docs[0]; // Assuming email addresses are unique
      if (userDoc.data().role !== "admin") {
        // If the user doesn't have admin role, show an error toast
        toast.error("Access denied. Admins only.");
        return;
      }
  
      // If the user exists and has admin role, send the password reset email
      await sendPasswordResetEmail(auth, email);
      toast.success('Check your email to reset your password!', {
        onClose: () => navigate("/admin/Adminsignin"),
        autoClose: 1000,
      });
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error resetting password:", error);
      toast.error("An error occurred while resetting password. Please try again later.");
    }
  };
        
  return (
    <React.Fragment>
        <ToastContainer />
      <div className="container_password">
        <div className="form-container_password">
          <img src={Profile} alt="Profile" className="profile-admin-img" />
          <h1 className="text-3xl text-center mt-3 ml-3 font-bold pb-8 fontinter">
            Forgot Password
          </h1>
          <form onSubmit={handleResetPassword}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Enter Your Email address"
              required
              className="  searchbox mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
            />
            {/* <div className="relative mb-6"> */}
            {/* <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder="Password"
                className=" searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              /> */}
            {/* {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : ( 
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )} */}
            {/* </div> */}
            {/* <div className="flex justify-between whitespace-nowrap text-sm sm:text-lg">
              
              <p>
                <Link
                  to="/forgot-password"
                  className="text-blue-600 hover:text-blue-800 transition duration-200 ease-in-out "
                >
                  Forgot password?
                </Link>
              </p>
            </div> */}
            <button
              style={{ marginTop: "1%" }}
              className=" w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit" 
            >
              Reset Password
            </button>
            <div style={{marginTop: "4%", display: 'flex', justifyContent: 'space-between'}}>
               <Link to='/admin/Adminsignin' className="link" style={{color:"blue", textDecoration:"none"}}>
               Existing Admin User ? 
               </Link>
               {/* <Link to='/admin/Adminsignup' className="link" style={{color:"blue", textDecoration:"none"}}>
               Create New Admin User
               </Link> */}
               </div>
          </form>
          {/* {message && <p>{message}</p>} */}
        </div>
        <Link to='/' className="user-login-link" style={{ position: 'absolute', top: '10px', right: '10px', color: 'blue', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <AiOutlineUser className="user-login-icon" style={{ marginRight: '5px', fontSize: '50px' }} />
          User
        </Link>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
