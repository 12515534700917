import QRCode from "react-qr-code";
import React, { useRef, useState } from "react";
import { Stack, CardMedia, Card, Button, IconButton } from "@mui/material";
import ReactPrint from "react-to-print";
import { Box } from "@mui/material";
import { FlatButton } from "./commonComponents";
import { AddApparel } from "./product";
import logoImage from "../images/Indian Design Logo.png";

const ZebraBrowserPrintWrapper = require("zebra-browser-print-wrapper");

export const Qrcode = (props) => {
  const [backtoApparel, setBacktoApparel] = useState(false);
  const {
    uniqueRecordId,
    stylename,
    stylecode,
    sampletype,
    washtype,
    size,
    fabcode,
    count,
    construction,
    composition,
    gsm,
  } = props;
  const qrCodeValue = `${uniqueRecordId}-${stylename}-${stylecode}-${sampletype}-${washtype}-${size}-${fabcode}-${count}-${construction}-${composition}-${gsm}`;
  const ref = useRef();

  return (
    <React.Fragment>
      <Box style={{ position: "relative", width: 200, height: 200 }}>
        <QRCode size={200} value={qrCodeValue} />
        <img
          src={logoImage}
          alt="Logo"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40px",
            height: "40px",
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default Qrcode;
