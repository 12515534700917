import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fs } from "../../firebase";
import Header from "../../components/Header";
import ProductCard from "./Components/ProductCard"; // Ensure this is correctly imported
import "../../pages/CategoryPage.css";
import { Circles } from 'react-loader-spinner';

const ProductDetailsPage = () => {
  const navigate = useNavigate();
  const { uniqueId } = useParams();
  const [product, setProduct] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const docRef = fs.collection("AparellData").doc(uniqueId);
      const doc = await docRef.get();
      if (doc.exists) {
        setProduct({ uniqueId: doc.id, ...doc.data() });
      } else {
        console.log("No such product!");
      }
      setLoading(false);
    };
    fetchProduct();
  }, [uniqueId]);

  return (
    <div>
      <Header />
      {product && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2% 1% 0 1%', textAlign: 'center' }}>
          <div className="back-icon_category" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            &lt; Back
          </div>
          <h1 style={{ marginLeft: "1%"}}>
            Results for : {product.stylename}
          </h1>
          <div style={{ width: 48 }} /> {/* Placeholder to balance the flex layout */}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        {loading ? (
          <Circles
            height={80}
            width={80}
            color="#007bff"
            ariaLabel="loading"
          />
        ) : (
          <div onClick={() => setShowDetails(true)}>
            <ProductCard item={product} />
            {showDetails && (
              <div>
                {/* Detailed view of the product */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsPage;