import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { AiFillEye, AiFillEyeInvisible, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import Profile from "../../images/admin logo.png"

export default function AdminSignup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleAdminSignUp = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Assign 'admin' role
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        role: "admin"
      });

      toast.success('Admin created successfully!', {
        onClose: () => navigate("/admin/AdminDashboard"), // Navigate to admin dashboard
        autoClose: 2000,
      });
    } catch (error) {
      setError(error.message);
      console.error("Error creating admin:", error);
      toast.error(error.message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="container_signup">
        <div className="form-container_signup">
        <img src={Profile} alt="Profile" className="profile-admin-img" />
          <h1 className="text-3xl text-center mt-3 ml-3 font-bold pb-8">Admin Signup</h1>
          <form onSubmit={handleAdminSignUp}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
            />
            <div className="relative mb-6">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
              {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            <button
              className="w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              Register for Control
            </button>
            <div style={{ marginTop: "4%", display: 'flex', justifyContent: 'space-between' }}>
            <Link to='/admin/Adminforgotpassword' className="link" style={{ color: "blue", textDecoration: "none" }}>
                Forgot Password ?
              </Link>
            <Link to='/admin/Adminsignin' className="link" style={{color:"blue", textDecoration:"none", display: 'block'}}>
              Already have an Account ?
            </Link>
            </div>
          </form>
        </div>
        <Link to='/' className="user-login-link" style={{ position: 'absolute', top: '10px', right: '10px', color: 'blue', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <AiOutlineUser className="user-login-icon" style={{ marginRight: '5px', fontSize: '50px' }} />
          User
        </Link>
      </div>
    </React.Fragment>
  );
}