import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"
import Design from "./pages/Design"
import Signin from "./pages/Signin"
import { Header } from "./components/Header";
import Qrcode from "./pages/Qrcode";
import {ProductDetails} from './pages/product'
import { Addfabric } from "./pages/Addfabric";
import {FabricGallery} from  "./pages/FabricGallery";
import {Merchant} from "./pages/Merchant";
import { MerchantGallery } from "./pages/MerchantGallery";
import PrintCom from "./pages/PrintCom";
import { AddApparel } from './pages/product';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Signup from './pages/Signup';
import LandingPage from './pages/LandingPage';
import Mens from './pages/Mens';
import CategoryPage from './pages/CategoryPage';
import GenderCategoryPage from './pages/GenderCategory';
import EditApparel from './pages/EditApparel';
import "./App.css"
import ProductDetailsPopup from './pages/product/ProductDetailsPopup';
import ProductDetailsPage from './pages/product/ProductDetailsPage';
import SearchResultsPage from './pages/SearchResultsPage';
import Adminsignin from './pages/admin_module/Adminsignin';
import Adminsignup from './pages/admin_module/Adminsignup';
import Adminforgotpassword from './pages/admin_module/Adminforgotpassword';
import AdminDashboard from './pages/admin_module/AdminDashboard';

function App() {

  return (
    <>
    <Router>
      {/* <div className='flex flex-col'>
  <Header/> */}
      <Routes>

{/* <---------------------------------------User Routes for Men, Women and Kids Wear----------------------------------> */}
     
        <Route path="/" element={<Signin/>} />
        <Route path="/Home" element={<Home/>} />
        <Route path='/LandingPage' element={<LandingPage/>} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/category/:category/:subCategory" element={<GenderCategoryPage />} />
        <Route path="/product/:uniqueId" element={<ProductDetailsPage />} />
        <Route path="/search/:query" element={<SearchResultsPage />} />
        <Route path='/Mens' element={<Mens/>} />
        <Route path="/Forgotpassword" element={<ForgotPassword/>} />
        <Route path="/Changepassword" element={<ChangePassword/>} />
        <Route path="/Signup" element={<Signup/>} /> 
        <Route path="/Home" element={<Home/>} />
        <Route path="/AddApparel" element={<AddApparel/>} />
        <Route path="/EditApparel/:id" element={<EditApparel/>} />
        <Route path="/Design" element={<Design/>} />
        <Route path="/Qrcode" element={<Qrcode/>} />
        <Route path="/products/:productId" element={<ProductDetails />} />

{/* <----------------------------------------Admin Routes-------------------------------------------------->  */}

        <Route path="/admin/Adminsignin" element={<Adminsignin />} />
        <Route path="/admin/Adminsignup" element={<Adminsignup/>} />
        <Route path="/admin/Adminforgotpassword" element={<Adminforgotpassword />} />
        <Route path="/admin/AdminDashboard" element={<AdminDashboard/>} />

        
        {/* <Route path="/ProductDetails" element={<ProductDetails/>} /> */}
        <Route path="/Addfabric" element={<Addfabric/>} />
        <Route path="/FabricGallery" element={<FabricGallery/>} />
        <Route path="/Merchant" element={<Merchant/>} />
        <Route path="/MerchantGallery" element={<MerchantGallery/>} />
        <Route path="/PrintCom" element={<PrintCom/>} />
      </Routes>
      {/* </div> */}
    </Router>
    </>
  );
}

export default App;
