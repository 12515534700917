import React from 'react'
import { IndividualProduct2 } from './IndividualProduct2'

export const Products2 = ({products2}) => {
  return products2.map((individualProduct2)=>(
    <IndividualProduct2 key={individualProduct2.ID} individualProduct2={individualProduct2}/>
  )
    
  )
}
