import React, {useState} from 'react'


export const IndividualProductFilter = ({individualProductFilter, handleClick}) => {
  return (
<div className='products-container '>
  <div className='product-card object-cover hover:scale-105 transition-scale duration-200 ease-in'>
    <div className='product-img'>
    <img src={individualProductFilter.imageUrls[0]} onClick={()=>handleClick(individualProductFilter)} /></div>
        <div className='product-name'>{individualProductFilter.stylename}</div>
         <p className='product-price'>${individualProductFilter.fobcost}</p>
    </div>
 </div>

  )
}
export default IndividualProductFilter;