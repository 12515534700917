import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetails from "./Components/ProductDetails";  // Assuming this is a presentational component for displaying details

function ProductDetailsPopup() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product data from your server or state management
    fetch(`/api/products/${productId}`)
      .then(res => res.json())
      .then(data => setProduct(data))
      .catch(err => console.error("Failed to fetch product", err));
  }, [productId]);

  if (!product) {
    return <p>Loading...</p>;
  }

  return <ProductDetails productDetails={product} />;
}

export default ProductDetailsPopup;