import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { fs } from "../firebase"; // Adjust the import path as necessary
import "./CategoryPage.css";
import Pagination from "./Pagination";
import ProductCard from "../pages/product/Components/ProductCard";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Header from "../components/Header";
import { Circles } from 'react-loader-spinner';

const CategoryPage = () => {
  const { category } = useParams(); // This matches the route parameter
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // Adjust the itemsPerPage to change when navigating to the next page.
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [activeCategory, setActiveCategory] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      console.log(`Fetching category: ${category}`)
      setLoading(true);
      const q = query(
        collection(fs, "AparellData"),
        where("category", "==", category.toUpperCase())
        // where("category", "==", "MEN")
      );
      const querySnapshot = await getDocs(q);
      console.log(`Found ${querySnapshot.docs.length} items`);
      setItems(querySnapshot.docs.map((doc) => doc.data()));
      setTotalItemCount(querySnapshot.size); // Set the total count of fetched items
      setLoading(false);
    };

    fetchData();
  }, [category]);

  // Calculate the indices of the first and last items on the current page
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  // Create an array for just the current page's items
  const currentItems = items.slice(firstItemIndex, lastItemIndex);

  // Function to navigate to the sub-category page
  const handleNavigate = (subCategory) => {
    navigate(`/category/${category}/${subCategory}`);
  };

  const handleCategorySelect = (category) => {
    // setSelectedCategory(category); // Assuming you have this function
    setActiveCategory(category); // Set the active category
    handleNavigate(category); // Navigate or perform other actions
  };

  const handleOnclickOfProduct = (productDetails) => {
    console.log("Product clicked", productDetails);
    setSelectedProduct(productDetails);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedProduct(null); // Optionally reset the selected product when closing
  };

  const shouldDisplayPagination = items.length > itemsPerPage;

  return (
    <>
      <Header />
      <div className="category-header">
        <div className="back-icon_category" onClick={() => navigate(-1)}>
          &lt; Back
        </div>

        <h1>{category}'s Wear</h1>
        <h2 className="total">Total Items: {totalItemCount}</h2>
      </div>
      {/* Buttons for navigating to sub-categories */}
      <div className="sub-category-buttons">
        <button
          className={`ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded ${
            activeCategory === "top-wear" ? "active-category-button" : ""
          }`}
          onClick={() => handleCategorySelect("top-wear")}
        >
          Top Wear
        </button>
        <button
          className={`ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded ${
            activeCategory === "bottom-wear" ? "active-category-button" : ""
          }`}
          onClick={() => handleCategorySelect("bottom-wear")}
        >
          Bottom Wear
        </button>
        <button
          className={`ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded ${
            activeCategory === "bottom-wear" ? "active-category-button" : ""
          }`}
          onClick={() => handleCategorySelect("lounge-wear")}
        >
          Lounge Wear
        </button>
        <button
          className={`ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded ${
            activeCategory === "bottom-wear" ? "active-category-button" : ""
          }`}
          onClick={() => handleCategorySelect("denim-wear")}
        >
          Denim Wear
        </button>
      </div>
      <div className="products-grid">
      {loading ? ( // Check if loading is true
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <Circles
              height={80}
              width={80}
              color="#007bff"
              ariaLabel="loading"
            />
          </div>
        ) : currentItems.length > 0 ? (
          currentItems.map((item, index) => (
            <ProductCard
              key={index}
              item={item}
              onClick={handleOnclickOfProduct}
            />
          ))
        ) : (
          <h1>No items found for this Category !!</h1>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          {selectedProduct && (
            <div>
              <h2>{selectedProduct.stylename || "No Style Name"}</h2>
              {/* Render other product details here */}
            </div>
          )}
        </DialogContent>
      </Dialog>
      {shouldDisplayPagination && (
        <Pagination
          totalPosts={items.length}
          postsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default CategoryPage;
