import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import DeleteIcon from "@mui/icons-material/Delete";
import { fs, storage, auth } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import Qrcode from "./Qrcode";
import { InputField, ProductDropDown } from "./product";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EditApparel.css";

const EditApparel = (props) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming 'id' is the document ID for the apparel to edit
  const [image, setImage] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [newImages, setNewImages] = useState([]);
  // State hooks for form fields
  const [formData, setFormData] = useState({
    uniqueRecordId: "",
    stylename: "",
    stylecode: "",
    typecode: "Select Product Code",
    sampletype: "Select Sample Type",
    producttype: "Select Product Type",
    category: "Select Category",
    subCategory: "Select Sub Category",
    stylesize: "",
    color: "",
    washtype: "",
    season: "",
    fobcost: "",
    ocnumber: "",
    orderqty: "",
    gpt: "",
    fabcode: "",
    fabricdesc: "",
    count: "",
    construction: "",
    composition: "",
    gsm: "",
    cuttable: "",
    imageUrls: [],
    typecode: "",
    comments: "",
  });

  const { productDetails } = props;
  const [producttype, setProducttype] = useState("Select Product Type");
  const [productCodeList, setProductCodeList] = useState([]);
  const [sampleTypeList, setSampleTypeList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [uniqueRecordId, setUniqueRecordId] = useState("");

  useEffect(() => {
    if (formData.typecode) {
      const newUniqueId = `IDCA-${formData.typecode}-${Date.now()}`;
      setUniqueRecordId(newUniqueId);
    }
  }, [formData.typecode]);

  useEffect(() => {
    // Fetch existing document data by ID and populate form
    const fetchApparelData = async () => {
      const docRef = fs.collection("AparellData").doc(id);
      const doc = await docRef.get();

      if (doc.exists) {
        const data = doc.data();

        setFormData((prevData) => ({
          ...prevData,
          ...data,
          uniqueRecordId: data.uniqueId || "No ID Found", // Make sure 'uniqueRecordId' is the correct field name as stored in Firestore
        }));
      } else {
        console.log("No such document!");
      }
    };

    fetchApparelData();
  }, [id]);

  const getProductCodesValues = async () => {
    const value = await fs.collection("ProductCode").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setProductCodeList(data.ProductList);
    }

    if (productDetails !== null && productDetails !== undefined) {
      console.log(productDetails.producttype);
      setProducttype(productDetails.producttype);
    }
  };

  useEffect(() => {
    getProductCodesValues();
    getSampleTypeList();
    getProductTypeList();
    getCategoryList();
    getSubCategoryList();
  }, []);

  const getSampleTypeList = async () => {
    const value = await fs.collection("SampleType").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setSampleTypeList(data.Sample);
    }
  };
  const getProductTypeList = async () => {
    const value = await fs.collection("ProductTypeList").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setProductTypeList(data.ProductType);
    }
  };
  const getCategoryList = async () => {
    const value = await fs.collection("CategoryList").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setCategoryList(data.Category);
    }
  };
  const getSubCategoryList = async () => {
    const value = await fs.collection("SubCategoryList").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setSubCategoryList(data.SubCategory);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the 'stylename' field is being updated
    if (name === "stylename") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        stylename_lower: value.toLowerCase(), // Automatically update the lowercase version
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDropdownChange = (value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "typecode" && {
        uniqueRecordId: `IDCA-${value}-${Date.now()}`,
      }),
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImageData = {
          file: file,
          previewUrl: reader.result,
        };
        setNewImages((prevImages) => [...prevImages, newImageData]);
      };
      reader.readAsDataURL(file);
    }
  };

  const DraggableImage = ({ url, index, moveImage, onDelete }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: "image",
      hover(item) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        moveImage(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "image",
      item: () => {
        return { url, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <div
        ref={ref}
        style={{
          width: 100,
          height: 100,
          position: "relative",
          opacity: isDragging ? 0.5 : 1,
          margin: "5px",
        }}
      >
        <img
          src={url}
          style={{ width: "100%", height: "100%" }}
          alt="Draggable"
        />
        <IconButton
          onClick={() => onDelete(url)}
          style={{ position: "absolute", top: 0, right: 0, color: "red" }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </div>
    );
  };

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageUrls: update(prevFormData.imageUrls, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevFormData.imageUrls[dragIndex]],
        ],
      }),
    }));
  }, []);

  const onDelete = (url) => {
    const updatedImages = formData.imageUrls.filter(
      (imageUrl) => imageUrl !== url
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageUrls: updatedImages,
    }));

    const fileRef = storage.refFromURL(url);
    fileRef.delete().catch((error) => {
      console.error("Error removing image from storage:", error);
    });

    fs.collection("ApparelData").doc(id).update({
      imageUrls: updatedImages,
    });
    logActivity(
      "Image deleted",
      formData.stylename,
      formData.uniqueRecordId
    );
  };

  const renderImages = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {formData.imageUrls.map((url, index) => (
          <DraggableImage
            key={url}
            url={url}
            index={index}
            moveImage={moveImage}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  };

  const logActivity = async (
    activityDescription,
    productName,
    uniqueRecordId
  ) => {
    try {
      const user = auth.currentUser;
  
      // Get current date and time
      const currentDate = new Date();
  
      // Convert current date and time to Indian Standard Time (IST)
      const istDate = currentDate.toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
  
      await fs.collection("ActivityLogs").add({
        user: user.email,
        activity: activityDescription,
        productName: `${productName} (${uniqueRecordId})`, // Concatenate product name and unique record id
        timestamp: istDate, // Use IST timestamp
      });
  
      console.log("Activity logged:", activityDescription);
    } catch (error) {
      console.error("Error logging activity: ", error);
    }
  }

  const handleUploadimagesToFireStore = () => {
    if (image) {
      // Check if there is a new image to upload
      let toastId = null;
      const uploadTask = storage
        .ref(`apparel-images/${Date.now()}-${image.name}`)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (!toastId) {
            toastId = toast("Upload is 0% done", {
              position: "top-right",
              autoClose: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
          } else {
            toast.update(toastId, {
              render: `Upload is ${progress}% done`,
              progress: progress / 100,
            });
          }
        },
        (error) => {
          toast.update(toastId, {
            render: `Error uploading image: ${error.message}`,
            type: toast.error,
            autoClose: 5000,
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            updateFirestoreDocument(downloadURL, true); // Pass true indicating new image is uploaded
          });
        }
      );
    } else {
      updateFirestoreDocument(null, false); // No new image uploaded
    }
  };

  const updateFirestoreDocument = (newImageUrl = null, isNewImageUploaded) => {
    const updateData = { ...formData };

    fs.collection("AparellData")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let updatedImageUrls = [...formData.imageUrls];
          if (newImageUrl) {
            updatedImageUrls.push(newImageUrl); // Add the new image URL
          }
          fs.collection("AparellData")
            .doc(id)
            .update({ ...updateData, imageUrls: updatedImageUrls })
            .then(() => {
              if (isNewImageUploaded) {
                toast.success("Data updated successfully with new images!", {
                  onClose: () => {
                    navigate(0);
                  },
                  autoClose: 1000,
                });
                logActivity(
                  "Apparel data updated with new images",
                  formData.stylename,
                  formData.uniqueRecordId
                );
              } else {
                toast.success("Data updated successfully!", {
                  onClose: () => {
                    navigate(0);
                  },
                  autoClose: 1000,
                });
                logActivity(
                  "Apparel data updated",
                  formData.stylename,
                  formData.uniqueRecordId
                );
              }
            })
            .catch((error) => {
              console.error("Error updating document:", error);
              toast.error("Error updating document: " + error.message, {
                autoClose: 5000,
              });
            });
        } else {
          console.error("Document not found!");
          toast.error("Document not found!", { autoClose: 5000 });
        }
      });
  };

  const handleRemoveNewImage = (index) => {
    setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleDeleteImage = async (urlToDelete) => {
    const updatedImageUrls = formData.imageUrls.filter(
      (url) => url !== urlToDelete
    );
    setFormData((prev) => ({ ...prev, imageUrls: updatedImageUrls }));

    // Optionally delete the file from Firebase Storage
    const fileRef = storage.refFromURL(urlToDelete);
    fileRef
      .delete()
      .then(() => {
        console.log("Image deleted from storage");
        toast.success("Image deleted successfully!", {
          position: "top-right",
          autoClose: 1000, // 1000 milliseconds = 1 second
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        logActivity(
          "Image deleted",
          formData.stylename,
          formData.uniqueRecordId
        );
      })
      .catch((error) => {
        console.error("Error removing image from storage:", error);
        toast.error("Error deleting image from storage", {
          position: "top-right",
          autoClose: 1000, // 1000 milliseconds = 1 second
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    // Update Firestore document
    fs.collection("AparellData")
      .doc(id)
      .update({
        imageUrls: updatedImageUrls,
      })
      .then(() => {
        console.log("Firestore document updated");
      })
      .catch((error) => {
        console.error("Error updating document:", error);
        toast.error("Error updating Firestore document");
      });
  };

  const displayImage =
    formData.imageUrls.length > 0 ? formData.imageUrls[0] : "";

  // Generate the form fields similar to AddApparel but with handleChange handlers
  return (
    <React.Fragment>
      <ToastContainer />
      <DndProvider backend={HTML5Backend}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="back-icon" onClick={() => navigate(-1)}>
            &lt; Back
          </div>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6" sx={{ color: "#007bff", paddingTop: 2 }}>
              EDIT APPAREL
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "GrayText", mt: 1, mb: 2 }}
            >
              Unique Document ID : {formData.uniqueId || "Not Available"}
            </Typography>
            <Typography variant="body1" sx={{ color: "GrayText" }}>
              Unique Product Code : {uniqueRecordId || "Not Available"}
            </Typography>
            {/* Example for handling input fields */}
            <Box sx={{ paddingLeft: 6, paddingTop: 2 }}>
              <Box sx={{ paddingBottom: 2 }}>
                <Stack spacing={5} direction="row">
                  <ProductDropDown
                    menuValues={productCodeList}
                    dropDownText={formData.typecode}
                    label={"Select Product Code"}
                    onChange={(value) =>
                      handleDropdownChange(value, "typecode")
                    }
                  />
                  <ProductDropDown
                    menuValues={sampleTypeList}
                    dropDownText={formData.sampletype}
                    label={"Sample Type"}
                    onChange={(value) =>
                      handleDropdownChange(value, "sampletype")
                    }
                  />
                  <ProductDropDown
                    menuValues={categoryList}
                    dropDownText={formData.category}
                    label={"Select Category"}
                    onChange={(value) =>
                      handleDropdownChange(value, "category")
                    }
                  />
                  <ProductDropDown
                    menuValues={subCategoryList}
                    dropDownText={formData.subCategory}
                    label={"Select Sub Category"}
                    onChange={(value) =>
                      handleDropdownChange(value, "subCategory")
                    }
                  />
                  <ProductDropDown
                    menuValues={productTypeList}
                    dropDownText={formData.producttype}
                    label={"Select Product Type"}
                    onChange={(value) =>
                      handleDropdownChange(value, "producttype")
                    }
                  />
                </Stack>
              </Box>
              <Stack spacing={5} direction="row">
                <InputField
                  label={"Style Name"}
                  value={formData.stylename}
                  handleChange={handleChange}
                  name="stylename"
                />
                <InputField
                  label={"Style Code"}
                  value={formData.stylecode}
                  handleChange={(e) => handleChange(e)}
                  name="stylecode"
                />
                <InputField
                  label={"Style Size"}
                  value={formData.size}
                  handleChange={(e) => handleChange(e)}
                  name="size"
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Style Color"}
                  value={formData.color}
                  handleChange={(e) => handleChange(e)}
                  name="color"
                />
                <InputField
                  label={"Wash Type"}
                  value={formData.washtype}
                  handleChange={(e) => handleChange(e)}
                  name="washtype"
                />
                <InputField
                  label={"Season"}
                  value={formData.season}
                  handleChange={(e) => handleChange(e)}
                  name="season"
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"FOB Cost"}
                  value={formData.fobcost}
                  handleChange={(e) => handleChange(e)}
                  name="fobcost"
                />
                <InputField
                  label={"OC Number"}
                  value={formData.ocnumber}
                  handleChange={(e) => handleChange(e)}
                  name="ocnumber"
                />
                <InputField
                  label={"Order QTY"}
                  value={formData.orderqty}
                  handleChange={(e) => handleChange(e)}
                  name="orderqty"
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"GPT"}
                  value={formData.gpt}
                  handleChange={(e) => handleChange(e)}
                  name="gpt"
                />
                <InputField
                  label={"Comments"}
                  value={formData.comments}
                  handleChange={(e) => handleChange(e)}
                  name="comments"
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Fab Code"}
                  value={formData.fabcode}
                  handleChange={(e) => handleChange(e)}
                  name="fabcode"
                />
                <InputField
                  label={"Fabric Desc"}
                  value={formData.fabricdesc}
                  handleChange={(e) => handleChange(e)}
                  name="fabricdesc"
                />
                <InputField
                  label={"Count"}
                  value={formData.count}
                  handleChange={(e) => handleChange(e)}
                  name="count"
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Construction"}
                  value={formData.construction}
                  handleChange={(e) => handleChange(e)}
                  name="construction"
                />
                <InputField
                  label={"Composition"}
                  value={formData.composition}
                  handleChange={(e) => handleChange(e)}
                  name="composition"
                />
                <InputField
                  label={"Weight ( GSM / oz )"}
                  value={formData.gsm}
                  handleChange={(e) => handleChange(e)}
                  name="gsm"
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Cuttable Width"}
                  value={formData.cuttable}
                  handleChange={(e) => handleChange(e)}
                  name="cuttable"
                />

                <input
                  type="file"
                  onChange={handleImageChange}
                  disabled={formData.imageUrls.length >= 4}
                  style={{ marginTop: "26px" }}
                />
                {renderImages()}
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    alt="Preview"
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                )}
              </Stack>

              {/* Example for handling dropdown fields */}

              {/* You need to replicate the above ProductDropDown for each dropdown field, making sure to replace `sampletype` and 'sampletype' with the appropriate field name */}

              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadimagesToFireStore}
                sx={{ mt: 4, mb: 4, ml: 60 }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </DndProvider>
    </React.Fragment>
  );
};

export default EditApparel;
