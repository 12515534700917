import React,{useState, useEffect} from 'react'
import { Link, Navigate, redirect } from 'react-router-dom';
import { fs,storage } from '../firebase';
import QRCode from "./Qrcode";
import {AddProduct} from "./product";
import ReactPrint from 'react-to-print'
import { useRef } from 'react';
import {Addfabric} from './Addfabric'
import {InputField} from './product/Components/InputField'
import {Typography, Box, Stack, Dialog } from '@mui/material'


export const StickerDividend = (props) => {
   const divPrint = React.useRef(null);
   const [hmReference,setHmReference]=useState('');
   const [fabricSupplierT2,setFabricSupplierT2]=useState('');
   const [t2MaterialCode,setT2MaterialCode]=useState('');
   const [productType,setProductType]=useState('');



    const {fabricSupplier, materialCode, count, construction, composition, GSM, finish,
        cuttableWidth, weave, price, remarks} = props
        const ref=useRef()
        const handlesupplierChange = (hmReference)=>{
         setHmReference(hmReference)
       }
       const handleFabsupplierChange = (fabricSupplierT2)=>{
         setFabricSupplierT2(fabricSupplierT2)
       }
       const handleT2MaterialCodeChange = (t2MaterialCode)=>{
         setT2MaterialCode(t2MaterialCode)
       }
       const handleProductTypeChange = (productType)=>{
         setProductType(productType)
       }

  return (

 <React.Fragment>
   <div class=" pt-1 ml-2 mr-2">

       <h4 className='my-element-inter ml-40 '>DIVIDEND Sticker</h4>
       <Stack spacing={3} direction="row">
     <InputField label={'H&M Reference'} value={hmReference} handleChange={handlesupplierChange} />
     <InputField label={'FABRIC SUPPLIER(T2)'} value={fabricSupplierT2} handleChange={handleFabsupplierChange} />
     </Stack>
     <Stack spacing={3} direction="row">
     <InputField label={'T2 MATERIAL CODE'} value={t2MaterialCode} handleChange={handleT2MaterialCodeChange} />
     <InputField label={'PRODUCT TYPE'} value={productType} handleChange={handleProductTypeChange} />
     </Stack>

       <div ref={divPrint} className=''>

       <table className= 'centertable'>
      <thead>
      </thead>
      <tbody >
      <tr key={2} >
          <td className= ''>{'H&M Reference'}</td>
           <td className=''>{hmReference}</td>
          </tr>
          <tr key={2} >
          <td className= ''>{'SUPPLIER(T1)'}</td>
           <td className=''>{'INDIAN DESIGNS EXPORTS PVT. LTD.'}</td>
          </tr>
          <tr key={3}>
           <td className=''>{'T1 PRODUCT CODE'}</td>
           <td className=''>{materialCode}</td>
        </tr>
        <tr key={3}>
           <td className=''>{'FABRIC SUPPLIER(T2)'}</td>
           <td className=''>{fabricSupplierT2}</td>
        </tr>
        <tr key={3}>
           <td className=''>{'T2 MATERIAL CODE'}</td>
           <td className=''>{t2MaterialCode}</td>
        </tr>
        <tr key={4}>
           <td className=''>{'COUNT'}</td>
           <td className=''>{count}</td>
        </tr>
        <tr key={5}>
           <td className=''>{'CONSTRUCTION'}</td>
           <td className=''>{construction}</td>
        </tr>
        <tr key={6}>
           <td className=''>{'COMPOSITION'}</td>
           <td className=''>{composition}</td>
        </tr>
        <tr key={7}>
           <td className=''>{'GSM'}</td>
           <td className=''>{GSM}</td>
        </tr>
        <tr key={8}>
           <td className=''>{'FINISH'}</td>
           <td className=''>{finish}</td>
        </tr>
        <tr key={9}>
           <td className=''>{'CUTTABLE WIDTH'}</td>
           <td className=''>{cuttableWidth}</td>
        </tr>
        <tr key={10}>
           <td className=''>{'WEAVE'}</td>
           <td className=''>{weave}</td>
        </tr>
        
        <tr key={12}>
           <td className=''>{'DATE'}</td>
           <td className=''>{}</td>
        </tr>
        <tr key={13}>
           <td className=''>{'REMARKS'}</td>
           <td className=''>{remarks}</td>
        </tr>
        <tr key={13}>
           <td className=''>{'PRODUCT TYPE'}</td>
           <td className=''>{productType}</td>
        </tr>
     </tbody>
    </table>
    </div>
    <div class="flex justify-center">
    <ReactPrint 
      trigger={()=><button className=' bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' >Print</button>} content={()=>divPrint.current}/></div>
</div>


</React.Fragment>
  
  )
}
 export default StickerDividend;