import React,{useState,useEffect} from 'react'
import { fs } from '../firebase';
import {Link} from 'react-router-dom'
import IndividualProduct1 from './IndividualProduct1';
import ProductDetails1 from './ProductDetails1';
import {Dialog} from '@mui/material'
import Pagination from './Pagination';
import Addfabric from './Addfabric';

export const FabricGallery = () => {
    const [products1,setProducts1]=useState([]);
    const [productDetails1,setProductDetails1]=useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productEditOpen, setProductEditOpen] = useState(false);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);

    const [isFabricEdit, setIsFabricEdit] = useState(false);


    const getProducts=async()=>{
        const products1=await fs.collection('Products1').get();
        const productsArray=[];
        for(var snap of products1.docs){
          var data=snap.data();
          data.ID=snap.id;
          productsArray.push({
            ...data
          })
          if(productsArray.length===products1.docs.length){
            setProducts1(productsArray);
          }
        }
      }
    
      useEffect(()=>{
        getProducts();
      },[])

      const handleDialogToClose = () => {
        setDialogOpen(false);
      }
      const handleOnclickOfProduct1= (productDetails1)=>{
        setDialogOpen(true)
        setIsFabricEdit(true)
        setProductDetails1(productDetails1)
           }
           
           const handleEditProduct =(ProductDetails1)=>{
            setDialogOpen(false)
            setProductEditOpen(true)
           }


  

  return (
    <React.Fragment>
      <Dialog onClose={handleDialogToClose} open={dialogOpen} PaperProps={{
        sx:{
          width: '100%',
          maxWidth: '70%',
          overflowX: 'scroll',
          overflowY: 'scroll',
          scrollBehavior:'auto'
        }
      }}>
         {
           productDetails1 !== undefined || productDetails1 !== null
           ? <ProductDetails1 productDetails1= {productDetails1} handleEditProduct = {handleEditProduct}/>
           : null
           }
      </Dialog>
    
    <div className='flex flex-row pt-1 fontinter'> 
    <Link to="/Home">
      <button className='ml-1 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded'>Apparel</button>
      </Link>
      <Link to="/FabricGallery" className='ml-1'>
      <button className='bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded'>Fabric</button>
      </Link>
      <Link to="/MerchantGallery" className='ml-1'>
      <button className='bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded'>Merchant</button>
      </Link>
</div>
{
   productEditOpen
   ? <Addfabric productDetails1={productDetails1} />
   :(

<div className='products-container ml-3'>
    {products1.length> 0 
    ? products1.map((individualProduct1)=>(
       <IndividualProduct1 key={individualProduct1.ID} individualProduct1={individualProduct1} handleClick1={handleOnclickOfProduct1}/>))
    : null
    } 
    </div>
  )}
    {products1.length < 1 && (
      <div className='text-center '>    
      ADD Products.....</div>
    )}   
    <Pagination
                totalPosts={products1.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
    
   </React.Fragment>
       )
}
  export default FabricGallery;