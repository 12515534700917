// import React,{useState}from 'react'
// import { fs,storage } from '../firebase';
// import { v4 as uuid } from 'uuid';
import React from 'react'

export const Merchant = () => {
  return (
    <div>Merchant</div>
  )
}

// export const Merchant = () => {
//   const unique_id = uuid();
//   const small_id = unique_id.slice(0,8);
//   const [fabricsup,setFabricsup]=useState('');
//   const [materialcd,setmaterial]=useState('');
//   const [count,setCount]=useState('');
//   const [construction,setConstruction]=useState('');
//   const [composition,setComposition]=useState('');
//   const   [gsm,setGsm]=useState('');
//   const [cuttable,setCutablle]=useState('');
//   const [finish,setFinish]=useState('');
//   const [weave,setWeave]=useState('');
//   const [fabrictype,setFabrictype]=useState('');
//   const [price,setPrice]=useState('');
//   const [moq,setMoq]=useState('');
//   const [leadtime,setLeadtime]=useState('');
//   const [submitted,setSubmitted]=useState('');
//   const [fpt,setFpt]=useState('');
//   const [remarks,setRemarks]=useState('');
//   const [images,setImages]=useState([]);
//   const [url, setUrl] = useState([]);
//   const [formData, setFormData] = useState({});
//   const types=['image/jpeg','image/jpg','image/png',];

//   const handleProductImg=(e)=>{
//     if (e.target.files) {
//       setFormData((prevState) => ({
//         ...prevState,
//         images: e.target.files,
//       }));
//     }
//   }
  

//   const handleAddProducts=(e)=>{
//     e.preventDefault();
//     const promises = [];
//     images.map((image) => {
//    const uploadTask= storage.ref(`product-images2/${image.name}`).put(image);
//    promises.push(uploadTask);
//    uploadTask.on('state_changed',snapshot=>{
//     const progess=(snapshot.bytesTransferred/snapshot.totalBytes)*100 
//   },error=>setUploadError(error.message),()=>{
    
//     storage.ref('product-images2/').child(image.name).getDownloadURL().then(url=>{
//       fs.collection('Products2').add({
//         small_id,
//         fabricsup,
//         materialcd,
//         count,
//         construction,
//         composition,
//         gsm,
//         cuttable,
//         finish,
//         weave,
//         fabrictype,
//         Price:Number(price),
//         moq,
//         leadtime,
//         submitted,
//         fpt,
//         remarks,
//         url
        
//       }).then(()=>{
        
//         setSuccesMsg('successful');
//         setFabricsup('');
//         setmaterial('');
//         setCount('');
//         setConstruction('');
//         setComposition('');
//         setGsm('');
//         setCutablle('');
//         setFinish('');
//         setWeave('');
//         setFabrictype('');      
//         setPrice('');
//         setMoq('');
//         setLeadtime('');
//         setSubmitted('');
//         setFpt('');
//         setRemarks('');
//         document.getElementById('file').value='';
//         setImageError('');
//         setUploadError('');
//         setTimeout(()=>{
//           setSuccesMsg('');
//           setUrl((prevState) => [...prevState, url])
//         })
        
//       }).catch(error=>setUploadError(error.message))
//     })
//   }
//      )
  
//     })
//   }  
    
//     const [imageError,setImageError]=useState('');
//     const [successMsg,setSuccesMsg]=useState('');
//     const [uploadError,setUploadError]=useState('');

//   return(
//     <>
//     <br/>
//     <h1 className='head'>ADD Merchant</h1>

//   <div className='hd'>Unique Id -</div>
//       <p className='style'>IDEPL-FAB{small_id}</p>
    
//   {successMsg&&<>
//   <div>{successMsg}</div><br/></>}

//     <form autoComplete='off' onSubmit={handleAddProducts}>   
//     &nbsp;&nbsp;&nbsp;&nbsp;<label>Fabric Material(T2)</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setFabricsup(e.target.value)} value={fabricsup}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>T2 Material Code</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setmaterial(e.target.value)} value={materialcd}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Count</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setCount(e.target.value)} value={count}/><br/>
//      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Construction</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setConstruction(e.target.value)} value={construction}/>
//      &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Composition</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setComposition(e.target.value)} value={composition}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Gsm</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setGsm(e.target.value)} value={gsm}/><br/>
//       &nbsp;&nbsp;&nbsp;&nbsp;<label>Cuttable-width</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setCutablle(e.target.value)} value={cuttable}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Finish</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setFinish(e.target.value)} value={finish}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Weave</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setWeave(e.target.value)} value={weave}/><br/>
//      &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<label>Fabric-Type</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setFabrictype(e.target.value)} value={fabrictype}/>
//       &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Price</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; 
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setPrice(e.target.value)} value={price}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>MOQ</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setMoq(e.target.value)} value={moq}/><br/>
//      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<label>LeadTime</label>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setLeadtime(e.target.value)} value={leadtime}/>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Submitted to</label>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setSubmitted(e.target.value)} value={submitted}/>
//     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>FPT</label>&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setFpt(e.target.value)} value={fpt}/><br/>
      
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Remarks</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <input  className='textboxform' type='text' required 
//       onChange={(e)=>setRemarks(e.target.value)} value={remarks}/>
//      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <label>Upload Images</label>&nbsp;&nbsp;&nbsp;&nbsp;
//       <input type='file' id='file' multiple required 
//       onChange={handleProductImg}/>
//       <br/><br/><br/>
//       {imageError&&<>
//       <div>{imageError}</div><br/><br/></>}
//       <div className='padg'>
//        <button className='sbutton success' type='submit'>ADDFabric</button></div>
      
//     </form>
//     {uploadError&&<>
//     <div>{uploadError}</div></>}

//       </>
//   )
// }
