import React,{useState, useEffect} from 'react'
import { Link, Navigate, redirect } from 'react-router-dom';
import { fs,storage } from '../firebase';
import QRCode from "./Qrcode";
import {AddApparel} from "./product";

export const Design = () => {
  const [isQrCodeGenerated,setIsQrCodeGenerated]=useState(false);

  const [qrstylename,setQrStylename]=useState('');
  const [qrstylecode,setQrStylecode]=useState('');
  const [qrfobcost,setQrfobcost]=useState(0);
  const [qrsampletype,setQrSampletype]=useState('');
  const [qrproducttype,setQrProducttype]=useState('');
  const [qrcategory,setQrCategory]=useState('');
  const [qrsize,setQrSize]=useState('');
  const [qrcolor,setQrColor]=useState('');
  const [qrwashtype,setQrWashtype]=useState('');
  const [qrseason,setQrSeason]=useState('');
  const [qrocnumber,setQrOcnumber]=useState('');
  const [qrorderqty,setQrOrderqty]=useState('');
  const [qrgpt,setQrGpt]=useState('');
 const  [qrfabricdesc,setQraFbricdesc]=useState('')
  const [qrcount,setQrCount]=useState('')
  useEffect(()=>{
    
    if(qrstylename!== '' || qrstylecode !== '' ||qrsampletype !=='' || qrproducttype!=='' || qrcategory!=='' || qrsize!=='' ||qrcolor!=='' ||qrwashtype!==''||
    qrseason!==''||qrocnumber!==''||qrorderqty!==''||qrgpt!==''||qrfabricdesc!==''|| qrcount!== ''|| qrfobcost > 0){
      setIsQrCodeGenerated(true)
    }
  }, [qrseason,qrstylename,qrocnumber,qrorderqty,qrgpt, qrstylename, qrfobcost,qrfabricdesc,qrcount,qrstylecode,qrsampletype,qrproducttype,qrcategory,qrsize,qrcolor,qrwashtype])

const displayProductQrCode = (qrCodeValue)=>{
  
 const {stylename,stylecode,fobcost,producttype,sampletype,category,color,size,washtype,season,ocnumber,orderqty,gpt,fabricdesc,
  count,
  construction,
  composition,
  cuttable} = qrCodeValue
 
  setQrStylename(stylename)
  setQrStylecode(stylecode)
  setQrfobcost(fobcost)
 setQrProducttype(producttype)
 setQrSampletype(sampletype)
 setQrCategory(category)
 setQrColor(color)
 setQrSize(size)
 setQrWashtype(washtype)
 setQrSeason(season)
 setQrOcnumber(ocnumber)
 setQrOrderqty(orderqty)
 setQrGpt(gpt)
 setQrCount(count)
 setQraFbricdesc(fabricdesc)

}
  return (
    
  <div>
{
  isQrCodeGenerated === true
  ? <QRCode stylename= {qrstylename} stylecode= {qrstylecode}  fobcost={qrfobcost} producttype={qrproducttype} 
  sampletype={qrsampletype} category={qrcategory} color={qrcolor} size={qrsize} washtype={qrwashtype} season={qrseason} ocnumber={qrocnumber} orderqty={qrorderqty} gpt={qrgpt} fabricdesc={qrfabricdesc
  } count={qrcount}/>
  : <AddApparel handleQrCode = {displayProductQrCode}/>
}
  </div>
    
  )
}
 export default Design;