import React, { useEffect, useState } from "react";
import { fs, storage, auth } from "../../firebase";
import { useRef } from "react";
import { Typography, Box, Stack, Button, IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputField, ProductDropDown } from ".";
import { Checkbox, FormControlLabel } from "@mui/material";
import Qrcode from "../Qrcode";
import { useParams, useNavigate, Form } from "react-router-dom";
import "./AddApparel.css";

export const AddApparel = (props) => {
  const history = useNavigate();

  const { productDetails } = props;
  const [sampletype, setSampletype] = useState("Select Sample Type");
  const [producttype, setProducttype] = useState("Select Product Type");
  const [category, setCategory] = useState("Select Category");
  const [subCategory, setSubCategory] = useState("Select Sub Category");
  const [typecode, setTypeCode] = useState("Select Product Code");
  const [color, setColor] = useState();
  const [size, setSize] = useState();
  const [washtype, setWashtype] = useState();
  const [season, setSeason] = useState();
  const [fobcost, setFobcost] = useState();
  const [ocnumber, setOcnumber] = useState();
  const [orderqty, setOrderqty] = useState();
  const [gpt, setGpt] = useState();
  const [comments, setComments] = useState();
  const [fabcode, setFabcode] = useState();
  const [fabricdesc, setFabricDesc] = useState();
  const [count, setCount] = useState();
  const [construction, setConstruction] = useState();
  const [composition, setComposition] = useState();
  const [gsm, setGsm] = useState();
  const [cuttable, setCuttable] = useState();

  const [stylename, setStylename] = useState("");
  const [stylecode, setStylecode] = useState("");

  const [imagesList, setImagesList] = useState([]);
  const canvasRef = useRef();
  const [idPrefix, setIdPrefix] = useState("IDCA");
  const [imageError, setImageError] = useState("");
  const [successMsg, setSuccesMsg] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 10000000000)
  );
  const [productCodeList, setProductCodeList] = useState([]);
  const [sampleTypeList, setSampleTypeList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [uniqueId, setUniqueId] = useState("");
  const [uniqueRecordId, setUniqueRecordId] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [isQrCodeGenerated, setIsQrCodeGenerated] = useState(false);
  const [isRecordEdited, setIsRecordEdited] = useState(false);

  const { id } = useParams();

  const types = ["image/jpeg", "image/jpg", "image/png"];

  const navigate = useNavigate();

  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    if (productDetails !== null && productDetails !== undefined) {
      console.log("Product Details Loaded:", productDetails);
      const {
        category,
        subcategory,
        color,
        comments,
        uniqueId,
        stylename,
        stylecode,
        cuttable,
        gsm,
        composition,
        construction,
        count,
        fabricdesc,
        fabcode,
        gpt,
        orderqty,
        ocnumber,
        fobcost,
        season,
        washtype,
        size,
        producttype,
        sampletype,
        typecode,
        imageUrls,
      } = productDetails;

      setIsRecordEdited(true);
      setCategory(category);
      setSubCategory(subcategory);
      setColor(color);
      setStylename(stylename);
      setStylecode(stylecode);
      setComments(comments);
      setUniqueId(uniqueId);
      setUniqueRecordId(uniqueId);
      setSampletype(sampletype);
      setTypeCode(typecode);
      setSize(size);
      setWashtype(washtype);
      setSeason(season);
      setFobcost(fobcost);
      setOcnumber(ocnumber);
      setOrderqty(orderqty);
      setGpt(gpt);
      setFabcode(fabcode);
      setCount(count);
      setFabricDesc(fabricdesc);
      setConstruction(construction);
      setComposition(composition);
      setGsm(gsm);
      setCuttable(cuttable);
      setProducttype(producttype);
      setImageUrls(imageUrls);
    }
  }, [productDetails]);

  useEffect(() => {
    if (
      imageUrls.length > 0 &&
      imagesList.length > 0 &&
      imageUrls.length === imagesList.length
    ) {
      handleAddProducts();
    }
  }, [imageUrls]);

  const logActivity = async (
    activityDescription,
    productName,
    uniqueRecordId
  ) => {
    try {
      const user = auth.currentUser;
  
      // Get current date and time
      const currentDate = new Date();
  
      // Convert current date and time to Indian Standard Time (IST)
      const istDate = currentDate.toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
  
      await fs.collection("ActivityLogs").add({
        user: user.email,
        activity: activityDescription,
        productName: `${productName} (${uniqueRecordId})`, // Concatenate product name and unique record id
        timestamp: istDate, // Use IST timestamp
      });
  
      console.log("Activity logged:", activityDescription);
    } catch (error) {
      console.error("Error logging activity: ", error);
    }
  }

  const handleProductImg = (e) => {
    const newFiles = Array.from(e.target.files);
    const totalFiles = imagesList.length + newFiles.length;

    if (totalFiles > 4) {
      toast.warn(`You can only upload a maximum of 4 images !!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution if the limit is exceeded
    }

    const newImagePreviews = newFiles.map((file) => ({
      file,
      previewUrl: URL.createObjectURL(file),
    }));

    setImagesList((prevImages) => [...prevImages, ...newFiles]);
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newImagePreviews]);
  };

  const handleRemoveImage = (index) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    setImagesList((prev) => prev.filter((_, i) => i !== index));
  };

  const renderImagePreviews = () => (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
      {imagePreviews.map((image, index) => (
        <Box key={index} sx={{ width: 100, position: "relative" }}>
          <img
            src={image.previewUrl}
            alt={`Preview ${index}`}
            style={{ width: "100%", height: "auto" }}
          />
          <IconButton
            size="small"
            onClick={() => handleRemoveImage(index)}
            sx={{ position: "absolute", top: 0, right: 0, color: "red" }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  );

  const handleAddProducts = async () => {
    // Determine if the selected subcategory is 'denim'
    const isDenim = subCategory.toLowerCase() === "denim"; // Assuming 'denim' is your identifier

    // Construct the document to be saved in Firestore
    const productData = {
      uniqueId,
      uniqueRecordId,
      stylename,
      stylecode,
      sampletype,
      producttype,
      category,
      subCategory,
      size,
      color,
      washtype,
      season,
      fobcost,
      ocnumber,
      orderqty,
      gpt,
      gsm,
      comments,
      fabcode,
      fabricdesc,
      count,
      construction,
      composition,
      cuttable,
      imageUrls,
      typecode,
      isDenim, // Add isDenim dynamically based on subcategory
      stylename_lower: stylename.toLowerCase()
    };

    // Save the product data to Firestore
    try {
      await fs.collection("AparellData").doc(uniqueId).set(productData);
      setIsQrCodeGenerated(true);
      setSuccesMsg("Product successfully added!");
      document.getElementById("file").value = "";
      setTimeout(() => {
        setSuccesMsg("");
      }, 5000);
      console.log("Document successfully written!");
    } catch (error) {
      console.error("Error writing document: ", error);
      setUploadError("Error saving the product: " + error.message);
    }
  };

  const getProductCodesValues = async () => {
    const value = await fs.collection("ProductCode").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setProductCodeList(data.ProductList);
    }

    if (productDetails !== null && productDetails !== undefined) {
      console.log(productDetails.producttype);
      setProducttype(productDetails.producttype);
    }
  };
  useEffect(() => {
    getProductCodesValues();
    getSampleTypeList();
    getProductTypeList();
    getCategoryList();
    getSubCategoryList();
  }, []);

  const getSampleTypeList = async () => {
    const value = await fs.collection("SampleType").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setSampleTypeList(data.Sample);
    }
  };
  const getProductTypeList = async () => {
    const value = await fs.collection("ProductTypeList").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setProductTypeList(data.ProductType);
    }
  };
  const getCategoryList = async () => {
    const value = await fs.collection("CategoryList").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setCategoryList(data.Category);
    }
  };
  const getSubCategoryList = async () => {
    const value = await fs.collection("SubCategoryList").get();
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      setSubCategoryList(data.SubCategory);
    }
  };

  /**Handle style name change */
  const handleStyleNameChange = (e) => {
    setStylename(e.target.value);
  };
  /**Handle style Code change */
  const handleStyleCodeChange = (e) => {
    setStylecode(e.target.value);
  };
  /**Handle Style Size  change */
  const handleStyleSizeChange = (e) => {
    setSize(e.target.value);
  };
  /**Handle Style Color  change */
  const handleStyleColorChange = (e) => {
    setColor(e.target.value);
  };
  /**Handle WashType  change */
  const handleWashTypeChange = (e) => {
    setWashtype(e.target.value);
  };
  /**Handle Season  change */
  const handleSeasonChange = (e) => {
    setSeason(e.target.value);
  };
  /**Handle FOBCost  change */
  const handleFOBCostChange = (e) => {
    setFobcost(e.target.value);
  };
  /**Handle OcNumber  change */
  const handleOcNumberChange = (e) => {
    setOcnumber(e.target.value);
  };
  /**Handle OrderQty  change */
  const handleOrderQtyChange = (e) => {
    setOrderqty(e.target.value);
  };
  /**Handle GPT  change */
  const handleGPTChange = (e) => {
    setGpt(e.target.value);
  };
  /**Handle Comments  change */
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };
  /**Handle FabCode  change */
  const handleFabCodeChange = (e) => {
    setFabcode(e.target.value);
  };
  /**Handle FabDesc  change */
  const handleFabDesChange = (e) => {
    setFabricDesc(e.target.value);
  };
  /**Handle Count  change */
  const handleCountChange = (e) => {
    setCount(e.target.value);
  };
  /**Handle Construction  change */
  const handleConstructionChange = (e) => {
    setConstruction(e.target.value);
  };
  /**Handle Composition  change */
  const handleCompositionChange = (e) => {
    setComposition(e.target.value);
  };
  /**Handle GSM  change */
  const handleGSMChange = (e) => {
    setGsm(e.target.value);
  };
  /**Handle CuttableWidth change */
  const handleCuttableWidthChange = (e) => {
    setCuttable(e.target.value);
  };
  /**Handle sample type list */
  const handleSampleTypeList = () => {
    return sampleTypeList.map((element) => {
      return element;
    });
  };
  /**Handle  Product type list */
  const handleProductTypeList = () => {
    return productTypeList.map((element) => {
      return element;
    });
  };

  /**Handle  Category list */
  const handleCategoryList = () => {
    return categoryList.map((element) => {
      return element;
    });
  };

  const handleSubCategoryList = () => {
    return categoryList.map((element) => {
      return element;
    });
  };

  /**Handle sample type change */
  const handleSampleTypeChange = (sampleType) => {
    setSampletype(sampleType);
  };
  /**Handle Product type change */
  const handleProductTypeChange = (productType) => {
    setProducttype(productType);
  };
  /**Handle Category change */
  const handleCategoryTypeChange = (category) => {
    setCategory(category);
  };

  const handleSubCategoryTypeChange = (subcategory) => {
    setSubCategory(subcategory);
  };

  /**Handle ProductCodes change */
  const handleProductCodesChange = (ProductCodes) => {
    if (
      ProductCodes !== null &&
      ProductCodes !== undefined &&
      ProductCodes !== ""
    ) {
      const newId = `${idPrefix}-${ProductCodes}-${randomNumber
        .toString()
        .padStart(10, "0")}`;
      setUniqueId(newId);
      setUniqueRecordId(newId); // Ensure both IDs are set to the same value
    }

    setTypeCode(ProductCodes);
  };

  const handleUploadimagesToFireStore = () => {
    if (!validateInputs()) {
      return; // Stop the submission if validation fails
    }
    // Check if all required fields are filled
    const fields = [
      stylecode,
      stylename,
      fobcost,
      color,
      size,
      washtype,
      season,
      ocnumber,
      orderqty,
      gpt,
      fabcode,
      fabricdesc,
      count,
      construction,
      composition,
      gsm,
      cuttable,
    ];

    const isAnyFieldEmpty = fields.some((field) => !field); // Check if any field is empty

    if (isAnyFieldEmpty) {
      toast.warn("Please fill all details!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      return; // Exit the function if any field is empty
    }

    if (imagesList.length === 0) {
      toast.info("Please upload at least one image", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      return; // Exit the function if no images are uploaded
    }

    let toastId = toast("Starting upload...", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const urls = [];
    Promise.all(
      imagesList.map(async (image, index) => {
        const urlResult = await uploadImageAsPromise(image, index, toastId);
        urls.push(urlResult);
        if (urls.length === imagesList.length) {
          setImageUrls(urls);
          logActivity("Product added", stylename, uniqueRecordId);
          toast.success("Product added successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      })
    ).catch((error) => {
      toast.error(
        `An error occurred while uploading images: ${error.message}`,
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        }
      );
    });
  };

  //Handle waiting to upload each file using promise
  function uploadImageAsPromise(image, index, toastId) {
    return new Promise(function (resolve, reject) {
      const uploadTask = storage
        .ref(`apparel-images/${image.name}-${uniqueId}-${index}`)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          toast.update(toastId, {
            render: `Uploading... ${progress.toFixed(0)}%`,
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: progress / 100,
          });
        },
        (error) => {
          reject(error.message);
          toast.update(toastId, {
            render: `Upload failed: ${error.message}`,
            type: toast.error,
            autoClose: 5000,
          });
        },
        () => {
          storage
            .ref("apparel-images")
            .child(`${image.name}-${uniqueId}-${index}`)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
              toast.update(toastId, {
                render: "Upload successful!",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
              });
            });
        }
      );
    });
  }

  const validateInputs = () => {
    const fields = [
      { value: sampletype, name: "Sample Type" },
      { value: producttype, name: "Product Type" },
      { value: category, name: "Category" },
      { value: subCategory, name: "Sub Category" },
      { value: typecode, name: "Product Code" },
    ];

    const invalidFields = fields.filter(
      (field) => field.value === "Select " + field.name || !field.value
    );

    if (invalidFields.length > 0) {
      invalidFields.forEach((field) => {
        toast.warn(`Please select a ${field.name}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
      return false;
    }
    return true;
  };

  const [checkboxStates, setCheckboxStates] = useState({
    styleName: false,
    styleCode: false,
    fobCost: false,
    producttype: false,
    sampletype: false,
    category: false,
    subCategory: false,
    color: false,
    size: false,
    washtype: false,
    season: false,
    ocnumber: false,
    orderqty: false,
    gpt: false,
    fabcode: false,
    fabricdesc: false,
    count: false,
    construction: false,
    composition: false,
    gsm: false,
    cuttable: false,
  });

  const handlePrintQR = () => {
    const qrElement = document.getElementById("qrCodeContainer");
    if (qrElement) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><style>h4 { text-align: center; font-size: 17px; font-weight: 1000; color: black; }</style></head><body>"
      );
      printWindow.document.write(qrElement.innerHTML);
      printWindow.document.write(`<h4>${stylecode}</h4>`);
      printWindow.document.write(`<h4>${composition}</h4>`);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    } else {
      console.error("The QR code element does not exist.");
      toast.error("Failed to print QR code: element does not exist.");
    }
};

  const handleCheckboxChange = (event) => {
    setCheckboxStates({
      ...checkboxStates,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Box display="flex" justifyContent="center" alignItems="center">
        <div className="back-icon" onClick={() => navigate(-1)}>
          &lt; Back
        </div>
        {isQrCodeGenerated === true ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh" // Ensures that the Box takes at least the full height of the viewport
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" component="h2" sx={{ mb: 4, ml: 6 }}>
              QR Successfully Generated
            </Typography>
            <div id="qrCodeContainer">
              <Qrcode
                uniqueRecordId={uniqueRecordId}
                stylename={stylename}
                stylecode={stylecode}
                fobcost={fobcost}
                producttype={producttype}
                sampletype={sampletype}
                category={category}
                subCategory={subCategory}
                color={color}
                size={size}
                washtype={washtype}
                season={season}
                ocnumber={ocnumber}
                orderqty={orderqty}
                gpt={gpt}
                fabcode={fabcode}
                fabricdesc={fabricdesc}
                count={count}
                construction={construction}
                composition={composition}
                gsm={gsm}
                cuttable={cuttable}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrintQR}
              sx={{ mt: 7, width: 200, ml: 2 }}
            >
              Print QR
            </Button>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Typography variant="h6" sx={{ color: "#007bff;", paddingTop: 2 }}>
              {id ? "UPDATE APPAREL" : "ADD APPAREL"}
            </Typography>
            <Box sx={{ paddingLeft: 6 }}>
              <Typography
                variant="body1"
                sx={{ color: "GrayText", mt: 1, mb: 2, mr: 5 }}
              >
                Unique Document ID : {uniqueId}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "GrayText", mt: 1, mb: 2, mr: 5 }}
              >
                Unique Product Code : {uniqueRecordId}
              </Typography>
            </Box>

            <Box sx={{ paddingLeft: 6, paddingTop: 2 }}>
              <Box sx={{ paddingBottom: 2 }}>
                <Stack spacing={5} direction="row">
                  <ProductDropDown
                    menuValues={productCodeList}
                    onChange={handleProductCodesChange}
                    dropDownText={typecode}
                    label={"Product Code"}
                  />
                  <ProductDropDown
                    menuValues={sampleTypeList}
                    onChange={handleSampleTypeChange}
                    dropDownText={sampletype}
                    label={"Sample Type"}
                  />
                  <ProductDropDown
                    menuValues={categoryList}
                    onChange={handleCategoryTypeChange}
                    dropDownText={category}
                    label={"Category"}
                  />
                  <ProductDropDown
                    menuValues={subCategoryList}
                    onChange={handleSubCategoryTypeChange}
                    dropDownText={subCategory}
                    label={"Sub Category"}
                  />
                  <ProductDropDown
                    menuValues={productTypeList}
                    onChange={handleProductTypeChange}
                    dropDownText={producttype}
                    label={"Product Type"}
                  />
                </Stack>
              </Box>

              <Stack spacing={5} direction="row">
                <InputField
                  label={"Style Name"}
                  value={stylename}
                  handleChange={handleStyleNameChange}
                />
                <InputField
                  label={"Style Code"}
                  value={stylecode}
                  handleChange={handleStyleCodeChange}
                />
                <InputField
                  label={"Style Size"}
                  value={size}
                  handleChange={handleStyleSizeChange}
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Style Colour"}
                  value={color}
                  handleChange={handleStyleColorChange}
                />
                <InputField
                  label={"Wash Type"}
                  value={washtype}
                  handleChange={handleWashTypeChange}
                />
                <InputField
                  label={"Season"}
                  value={season}
                  handleChange={handleSeasonChange}
                />
              </Stack>

              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"FOB Cost"}
                  value={fobcost}
                  handleChange={handleFOBCostChange}
                />
                <InputField
                  label={"OC Number"}
                  value={ocnumber}
                  handleChange={handleOcNumberChange}
                />
                <InputField
                  label={"Order QTY"}
                  value={orderqty}
                  handleChange={handleOrderQtyChange}
                />
              </Stack>
              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"GPT"}
                  value={gpt}
                  handleChange={handleGPTChange}
                />
                <InputField
                  label={"Comments"}
                  value={comments}
                  handleChange={handleCommentsChange}
                />
              </Stack>
              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Fab Code"}
                  value={fabcode}
                  handleChange={handleFabCodeChange}
                />
                <InputField
                  label={"Fabric Desc"}
                  value={fabricdesc}
                  handleChange={handleFabDesChange}
                />
                <InputField
                  label={"Count"}
                  value={count}
                  handleChange={handleCountChange}
                />
              </Stack>
              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Construction"}
                  value={construction}
                  handleChange={handleConstructionChange}
                />
                <InputField
                  label={"Composition"}
                  value={composition}
                  handleChange={handleCompositionChange}
                />
                <InputField
                  label={"Weight ( GSM / oz )"}
                  value={gsm}
                  handleChange={handleGSMChange}
                />
              </Stack>
              <Stack spacing={5} direction="row" sx={{ paddingTop: 2 }}>
                <InputField
                  label={"Cuttable Width"}
                  value={cuttable}
                  handleChange={handleCuttableWidthChange}
                />

                <input
                  type="file"
                  id="file"
                  multiple
                  onChange={handleProductImg}
                  style={{ marginTop: "26px" }}
                />
                {renderImagePreviews()}
              </Stack>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadimagesToFireStore}
                sx={{ mt: 4, mb: 4, ml: 60 }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
export default AddApparel;
