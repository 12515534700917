import React, { useState } from "react";
import "./ForgotPassword.css"
import Profile from "../images/login1.png";
import { useNavigate } from "react-router";
// import firebase from '../firebase'
import globalObject from "../components/global";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { RiAdminLine } from "react-icons/ri";

function ForgotPassword() {
  // const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const { email } = formData;
  const navigate = useNavigate();
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  const handleResetPassword= async(e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email)
      toast.success('Check your email to reset your password!', {
        onClose: () => navigate("/"), // Navigate after the toast is dismissed
        autoClose: 2000,
      })
        

        //   try {
        //     await firebase.auth().sendPasswordResetEmail(email);
        //     setMessage('Check your email to reset your password.');
        //   } catch (error) {
        //     setMessage('Error: ' + error.message);
        //   }
        // localStorage.setItem("passwordResetEmailSent", "true");
        
        
      
    } catch (error) {
        toast.error("Not a Valid Email", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
    }
  }
  return (
    <React.Fragment>
        <ToastContainer />
      <div className="container_password">
        <div className="form-container_password">
          <img src={Profile} alt="Profile" className="profile-img" />
          <h1 className="text-3xl text-center mt-6 font-bold pb-8 fontinter">
            Forgot Password
          </h1>
          <form onSubmit={handleResetPassword}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Enter Your Email address"
              required
              className="  searchbox mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
            />
            {/* <div className="relative mb-6"> */}
            {/* <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder="Password"
                className=" searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              /> */}
            {/* {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : ( 
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )} */}
            {/* </div> */}
            {/* <div className="flex justify-between whitespace-nowrap text-sm sm:text-lg">
              
              <p>
                <Link
                  to="/forgot-password"
                  className="text-blue-600 hover:text-blue-800 transition duration-200 ease-in-out "
                >
                  Forgot password?
                </Link>
              </p>
            </div> */}
            <button
              style={{ marginTop: "1%" }}
              className=" w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit" 
            >
              Reset Password
            </button>
            <div style={{marginTop: "4%", display: 'flex', justifyContent: 'space-between'}}>
               <Link to='/' className="link" style={{color:"blue", textDecoration:"none"}}>
               Existing User ? 
               </Link>
               <Link to='/Signup' className="link" style={{color:"blue", textDecoration:"none"}}>
               Create New Account 
               </Link>
               </div>
          </form>
          {/* {message && <p>{message}</p>} */}
        </div>
        <Link to='/admin/Adminsignin' className="admin-login-link" style={{ position: 'absolute', top: '10px', right: '10px', color: 'blue', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <RiAdminLine className="admin-login-icon" style={{ marginRight: '5px', fontSize: '50px' }} />
          Admin
        </Link>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
