import React from 'react'
import {TextField, Typography, Box} from '@mui/material'

export const InputField = ({label, value, handleChange, name}) => { 
  

  return (
   <React.Fragment>
    <Box>
    <Typography variant='body1' sx={{fontfamily: 'Inter'}}>{label}</Typography>
     <TextField
     type = 'text'
     InputLabelProps={{
        shrink: true
     }}
     inputProps={{
        style:{
          height: '20px',
          width:"300px"
        }
     }}
     variant='outlined'
     size='small'
     value= {value}
     name={name}
     onChange={handleChange}
     />
   </Box>
   </React.Fragment>
  )

}
 export default InputField;