import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import globalObject from "../components/global";
import Logo from "../images/Indian_Design_Logo.png";
import { fs } from "../firebase";

export const Header = () => {
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [showSearchResults, setShowSearchResults] = useState(true);
  const navigate = useNavigate();
  const searchContainerRef = useRef(null);
  const searchResultsRef = useRef(null);

  useEffect(() => {
    const fetchProducts = async () => {
      if (searchQuery.trim() !== "") {
        try {
          const querySnapshot = await fs.collection("AparellData").get();
          const products = querySnapshot.docs.map((doc) => doc.data());
          const filtered = products.filter((product) =>
            product.stylename.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setFilteredResults(filtered);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      } else {
        setFilteredResults([]);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  // const handleSearch = (event) => {
  //   event.preventDefault();
  //   if (searchQuery.trim()) {
  //     navigate(`/search/${searchQuery}`);
  //     setFilteredResults([]);
  //     setShowSearchResults(false);
  //   }
  // };

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      // Check if the query contains a slash
      const searchTerm = searchQuery.includes('/')
        ? searchQuery.split('/')[0].trim() // Use the part before the slash
        : searchQuery; // Use the whole query if no slash
      // Navigate with the encoded search term
      navigate(`/search/${encodeURIComponent(searchTerm)}`);
      setFilteredResults([]); // Clear previous results
      setShowSearchResults(false); // Hide search results dropdown
    }
  };

  const handleKeyDown = (event) => {
    if (filteredResults.length > 0) {
      if (event.keyCode === 40) {
        // Down arrow key
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredResults.length - 1)
        );
      } else if (event.keyCode === 38) {
        // Up arrow key
        event.preventDefault();
        setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      } else if (event.keyCode === 13 && highlightedIndex >= 0) {
        // Enter key
        event.preventDefault();
        navigate(`/product/${filteredResults[highlightedIndex].uniqueId}`);
        setFilteredResults([]);
        setShowSearchResults(false);
      }
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setFilteredResults([]);
        setShowSearchResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      globalObject.userId = storedUserId;
      globalObject.userValidate = true;
    }
  }, []);

  // Function to handle sign out
  const handleSignOut = () => {
    globalObject.userValidate = false;
    globalObject.userId = "";
    localStorage.removeItem("userId"); // Remove user ID from local storage
    navigate("/"); // Redirect to home or sign-in page
  };

  const searchResultsDropdownStyle = {
    position: "absolute",
    top: "100%",
    left: "0",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 8px 8px",
    maxHeight: "220px",
    overflowY: "auto",
    zIndex: 1000,
  };

  const searchResultItemStyle = (isHighlighted) => ({
    padding: "10px 20px",
    cursor: "pointer",
    transition: "background-color 0.2s ease, color 0.2s ease",
    backgroundColor: isHighlighted ? "#007bff" : "white",
    color: isHighlighted ? "white" : "black",
  });

  const thinScrollbarStyle = {
    width: "5px",
  };

  const thinScrollbarTrackStyle = {
    background: "#f1f1f1",
  };

  const thinScrollbarThumbStyle = {
    background: "#007bff",
    borderRadius: "5px",
  };

  const thinScrollbarThumbHoverStyle = {
    background: "#0056b3",
  };

  return (
    <React.Fragment>
      <header
        className="header__middle"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="header__middle__logo" style={{ marginLeft: "2%" }}>
          {globalObject.userValidate && globalObject.isAdmin ? (
            <img
              src={Logo}
              alt="logo"
              style={{ height: "50px", cursor: "default" }}
            />
          ) : (
            <img
              src={Logo}
              alt="logo"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => {
                if (!(globalObject.userValidate && globalObject.isAdmin)) {
                  navigate("/LandingPage");
                }
              }}
            />
          )}
        </div>
        <div
          className="search-bar-container"
          style={{ flexGrow: 1, position: "relative" }}
          ref={searchContainerRef}
        >
          <form
            onSubmit={handleSearch}
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <input
              type="text"
              placeholder="Search Designs..."
              value={searchQuery}
              onChange={handleSearchInput}
              onKeyDown={handleKeyDown}
              style={{
                width: "100%",
                padding: "10px ",
                borderRadius: "30px",
                border: "2px solid #007bff",
                outline: "none",
              }}
            />
            <button
              type="submit"
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "#007bff",
                color: "white",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiSearch style={{ margin: "0" }} />
            </button>
          </form>
          {filteredResults.length > 0 && (
            <div style={searchResultsDropdownStyle} className="search-results-dropdown" ref={searchResultsRef}>
              <style>
                {`
                  /* Custom scrollbar styles */
                  .search-results-dropdown::-webkit-scrollbar {
                    width: ${thinScrollbarStyle.width};
                  }
                  .search-results-dropdown::-webkit-scrollbar-track {
                    background: ${thinScrollbarTrackStyle.background};
                  }
                  .search-results-dropdown::-webkit-scrollbar-thumb {
                    background: ${thinScrollbarThumbStyle.background};
                    border-radius: ${thinScrollbarThumbStyle.borderRadius};
                  }
                  .search-results-dropdown::-webkit-scrollbar-thumb:hover {
                    background: ${thinScrollbarThumbHoverStyle.background};
                  }
                `}
              </style>
              {filteredResults.map((product, index) => (
                <div
                  key={product.uniqueId}
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    backgroundColor:
                      highlightedIndex === index ? "#007bff" : "white",
                    color: highlightedIndex === index ? "white" : "black",
                    transition: "background-color 0.1s ease, color 0.1s ease",

                  }}
                  onClick={() => navigate(`/product/${product.uniqueId}`)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {product.stylename} - {product.category}
                </div>
              ))}
            </div>
          )}
        </div>

        <nav
          className="main-nav "
          style={{ display: "flex", alignItems: "center" }}
        >
          <ul
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 10px",
              padding: 0,
            }}
          >
            <li
              className="menu-item sub__menus__arrows"
              onMouseEnter={() => setMenuSubMenu(true)}
              onMouseLeave={() => setMenuSubMenu(false)}
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                Add Designs
              </Link>
              {isMenuSubMenu && (
                <ul className="sub__menus" style={{ padding: "16px" }}>
                  <li>
                    <NavLink to={`/AddApparel`}>Add Apparel</NavLink>
                  </li>
                  <li>
                    <NavLink to={`/Addfabric`}>Add Fabric</NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="menu-item">
              <NavLink to={`/`} onClick={handleSignOut}>
                Signout
              </NavLink>
            </li>
            {globalObject.userValidate && (
              <li
                className="menu-item"
                style={{ marginLeft: "15px", color: "#007bff" }}
              >
                {globalObject.userId}
              </li>
            )}
          </ul>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default Header;
