import { useState } from "react";
import React from 'react';
import "./Signin.css"
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import globalObject from "../components/global";
import Profile from "../images/login1.png"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import css
import { getDocs, collection, where, query } from 'firebase/firestore';
import { fs } from "../firebase";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Check if the user is declined
        const declinedUsersRef = collection(fs, 'declinedUsers');
        const declinedUsersQuery = query(declinedUsersRef, where('email', '==', email));
        const declinedUsersSnapshot = await getDocs(declinedUsersQuery);

        if (!declinedUsersSnapshot.empty) {
            // User's email is found in declinedUsers collection
            const declinedUser = declinedUsersSnapshot.docs[0].data();
            if (!declinedUser.approved) {
                // User's account has been declined
                toast.error(
                    'Your account has been DECLINED. Please contact your Administrator for more inquiries.', {
                      position: 'top-right',
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                  });
              
                return;
            }
        }

        // Check if the user is blocked
        const blockedUsersRef = collection(fs, 'blockedUsers');
        const blockedUsersQuery = query(blockedUsersRef, where('email', '==', email));
        const blockedUsersSnapshot = await getDocs(blockedUsersQuery);

        if (!blockedUsersSnapshot.empty) {
            // User's email is found in blockedUsers collection
            toast.error('Your account has been BLOCKED. Please contact your Administrator for further queries !!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
          });
            return;
        }

        const pendingUsersRef = collection(fs, 'pendingUsers');
        const pendingUsersQuery = query(pendingUsersRef, where('email', '==', email));
        const pendingUsersSnapshot = await getDocs(pendingUsersQuery);

        if (!pendingUsersSnapshot.empty) {
            // User's email is found in pendingUsers collection
            toast.info('Admin approval pending! Please contact the Admin for further inquiries.');
            return;
        }

        // Check if the user is approved
        const usersRef = collection(fs, 'users');
        const usersQuery = query(usersRef, where('email', '==', email));
        const usersSnapshot = await getDocs(usersQuery);

        if (usersSnapshot.empty) {
            // User not found in the users collection
            toast.error('User not found.');
            return;
        }

        const userData = usersSnapshot.docs[0].data();
        if (!userData.approved) {
            // User's account is pending approval
            toast.info('Admin approval pending! Please contact the Admin for further inquiries.');
            return;
        }

         // Proceed with sign-in
         globalObject.userId = email;  // Set the global object's userId
         globalObject.userValidate = true;  // Set the global object's userValidate to true
        
         // Success message
         toast.success('Signed in successfully!', {
          onClose: () => navigate("/LandingPage"),
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
      });
     } catch (error) {
         // Handle sign-in errors
         toast.error('Email or Password is Invalid', {
             position: 'top-right',
             autoClose: 2000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: false,
             progress: undefined,
         });
     }
 };
  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="container_signin">
    <div className="form-container_signin">
       <img src={Profile} alt="Profile" className="profile-img" />
        <h1 className="text-3xl text-center mt-6 font-bold pb-8 fontinter">Access Portal</h1>
          <form onSubmit={onSubmit}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Email address"
              className="  searchbox mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
            />
            <div className="relative mb-6">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder="Password"
                className=" searchbox w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
              {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : ( 
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>
            {/* <div className="flex justify-between whitespace-nowrap text-sm sm:text-lg">
              
              <p>
                <Link
                  to="/forgot-password"
                  className="text-blue-600 hover:text-blue-800 transition duration-200 ease-in-out "
                >
                  Forgot password?
                </Link>
              </p>
            </div> */}
            <button style={{marginTop: "2%"}}
              className=" w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit" 
            >
              Sign in
            </button>
            <div style={{marginTop: "4%", display: 'flex', justifyContent: 'space-between'}}>
               <Link to='/Forgotpassword' className="link" style={{color:"blue", textDecoration:"none"}}>
               Forgot Password ?
               </Link>
               <Link to='/Signup' className="link" style={{color:"blue", textDecoration:"none"}}>
               Create New Account 
               </Link>
               </div>  
          </form>
        </div>
        <Link to='/admin/Adminsignin' className="admin-login-link" style={{ position: 'absolute', top: '10px', right: '10px', color: 'blue', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <RiAdminLine className="admin-login-icon" style={{ marginRight: '5px', fontSize: '50px' }} />
          Admin
        </Link>
      </div>

    </React.Fragment>
  );
}

