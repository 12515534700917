import React, { useEffect, useState } from "react";
import { FaBaby, FaTrash, FaPlus } from "react-icons/fa";
import {
  GiQueenCrown,
  GiSharpCrown,
  GiWool,
  GiFamilyHouse,
} from "react-icons/gi";
import { FaBan, FaCheck, FaTimes, FaUnlock } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "./AdminDashboard.css";
import { Button } from "@mui/material";
import AdminHeader from "../admin_module/AdminHeader";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  setDoc,
  doc,
  addDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { fs } from "../../firebase";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [declinedUsers, setDeclinedUsers] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);
  const [apparelDropdowns, setApparelDropdowns] = useState([]);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [miniTab, setMiniTab] = useState("ApprovedUsers");
  const [productCounts, setProductCounts] = useState({
    Men: 0,
    Kid: 0,
    Women: 0,
    Fabric: 0,
    "Home-accessories": 0,
  });
  const [productCodeList, setProductCodeList] = useState([]);
  const [sampleTypeList, setSampleTypeList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [newProductCode, setNewProductCode] = useState("");
  const [newSampleType, setNewSampleType] = useState("");
  const [newProductType, setNewProductType] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");

  const categoryId = "gQ7tytWfp0CTYYTRFhLq";
  const subCategoryDocId = "0dUgm7vfiE3dY8OgyXJB";
  const productTypeDocId = "yDjQcA0FFAeN9a2PiA9N";

  const [dropdownTab, setDropdownTab] = useState("ProductCodes");

  useEffect(() => {
    getProductCodesValues();
    getSampleTypeList();
    getProductTypeList();
    getCategoryList();
    getSubCategoryList();
  }, []);

  useEffect(() => {
    getProductCodesValues();
    getSampleTypeList();
    getProductTypeList();
    getCategoryList();
    getSubCategoryList();
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchProductCodes();
    fetchSampleTypes();
    fetchSubCategoryList();
    fetchProductTypes();
  }, []);

  const fetchSampleTypes = async () => {
    try {
      const docRef = doc(fs, "SampleType", "4Ffm4xBBb9uK2fc5GPEP"); // Use the specific document ID
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const sampleTypeList = docSnap.data().Sample;
        setSampleTypeList(sampleTypeList);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching sample types:", error);
    }
  };

  const fetchProductCodes = async () => {
    try {
      const docRef = doc(fs, "ProductCode", "Dser1KxFY7RvV8DKEoia"); // Use the specific document ID
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const productList = docSnap.data().ProductList;
        setProductCodeList(productList);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching product codes:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const docRef = doc(fs, "CategoryList", categoryId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCategoryList(docSnap.data().Category);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategoryList = async () => {
    try {
      const docRef = doc(fs, "SubCategoryList", subCategoryDocId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const subCategories = docSnap.data().SubCategory;
        setSubCategoryList(subCategories);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchProductTypes = async () => {
    try {
      const docRef = doc(fs, "ProductTypeList", productTypeDocId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const productTypes = docSnap.data().ProductType;
        setProductTypeList(productTypes);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const handleAddProductCode = async () => {
    try {
      const newCode = newProductCode.trim();
      if (!newCode) {
        handleToast("Product Code cannot be empty", "warning");
        return;
      }

      const docRef = doc(fs, "ProductCode", "Dser1KxFY7RvV8DKEoia"); // Use the specific document ID
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const productList = docSnap.data().ProductList;
        if (productList.includes(newCode)) {
          handleToast("Product Code already exists", "warning");
          return;
        }

        await updateDoc(docRef, {
          ProductList: [...productList, newCode],
        });

        setProductCodeList([...productList, newCode]);
        setNewProductCode("");
        handleToast("Product Code added successfully", "success");
      } else {
        handleToast("Document not found", "error");
      }
    } catch (error) {
      console.error("Error adding Product Code:", error);
      handleToast("Error adding Product Code", "error");
    }
  };

  const handleAddSampleType = async () => {
    try {
      const newSample = newSampleType.trim();
      if (!newSample) {
        handleToast("Sample Type cannot be empty", "warning");
        return;
      }

      const docRef = doc(fs, "SampleType", "4Ffm4xBBb9uK2fc5GPEP");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const sampleList = docSnap.data().Sample;
        if (sampleList.includes(newSample)) {
          handleToast("Sample Type already exists", "warning");
          return;
        }

        const updatedSamples = [...sampleList, newSample];
        await updateDoc(docRef, { Sample: updatedSamples });
        setSampleTypeList(updatedSamples);
        setNewSampleType("");
        handleToast("Sample Type added successfully", "success");
      } else {
        console.error("No such document!");
        handleToast("No such document!", "error");
      }
    } catch (error) {
      console.error("Error adding Sample Type:", error);
      handleToast("Error adding Sample Type", "error");
    }
  };

  const handleAddCategory = async () => {
    const newCat = newCategory.trim();
    if (newCat === "") {
      handleToast("Category name cannot be empty", "warning");
      return;
    }

    if (categoryList.includes(newCat)) {
      handleToast("Category already exists", "warning");
      return;
    }

    const updatedCategoryList = [...categoryList, newCat];

    try {
      const docRef = doc(fs, "CategoryList", categoryId);
      await updateDoc(docRef, {
        Category: updatedCategoryList,
      });

      setNewCategory(""); // Clear the input field
      fetchCategories(); // Refresh the categories list
      handleToast("Category added successfully", "success");
    } catch (error) {
      console.error("Error adding category:", error);
      handleToast("Error adding category", "error");
    }
  };

  const handleAddSubCategory = async () => {
    try {
      const newSubCat = newSubCategory.trim();
      if (!newSubCat) {
        handleToast("Subcategory name cannot be empty", "warning");
        return;
      }

      const docRef = doc(fs, "SubCategoryList", subCategoryDocId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const existingSubCategories = docSnap.data().SubCategory;

        if (existingSubCategories.includes(newSubCat)) {
          handleToast("Subcategory already exists", "warning");
          return;
        }

        const updatedSubCategories = [...existingSubCategories, newSubCat];
        await updateDoc(docRef, { SubCategory: updatedSubCategories });

        setSubCategoryList(updatedSubCategories);
        setNewSubCategory("");
        handleToast("Subcategory added successfully", "success");
      } else {
        console.error("No such document!");
        handleToast("No such document!", "error");
      }
    } catch (error) {
      console.error("Error adding subcategory:", error);
      handleToast("Error adding subcategory", "error");
    }
  };

  const handleAddProductType = async () => {
    try {
      const newType = newProductType.trim();
      if (!newType) {
        handleToast("Product Type cannot be empty", "warning");
        return;
      }

      const docRef = doc(fs, "ProductTypeList", productTypeDocId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const existingTypes = docSnap.data().ProductType;

        if (existingTypes.includes(newType)) {
          handleToast("Product Type already exists", "warning");
          return;
        }

        const updatedTypes = [...existingTypes, newType];
        await updateDoc(docRef, { ProductType: updatedTypes });

        setProductTypeList(updatedTypes);
        setNewProductType("");
        handleToast("Product Type added successfully", "success");
      } else {
        console.error("No such document!");
        handleToast("No such document!", "error");
      }
    } catch (error) {
      console.error("Error adding Product Type:", error);
      handleToast("Error adding Product Type", "error");
    }
  };

  const getProductCodesValues = async () => {
    const value = await getDocs(collection(fs, "ProductCode"));
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      productsArray.push(data.code);
    }
    setProductCodeList(productsArray);
  };

  const getSampleTypeList = async () => {
    const value = await getDocs(collection(fs, "SampleType"));
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      productsArray.push(data.type);
    }
    setSampleTypeList(productsArray);
  };

  const getProductTypeList = async () => {
    const value = await getDocs(collection(fs, "ProductTypeList"));
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      productsArray.push(data.type);
    }
    setProductTypeList(productsArray);
  };

  const getCategoryList = async () => {
    const value = await getDocs(collection(fs, "CategoryList"));
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      productsArray.push(data.category);
    }
    setCategoryList(productsArray);
  };

  const getSubCategoryList = async () => {
    const value = await getDocs(collection(fs, "SubCategoryList"));
    const productsArray = [];
    for (var snap of value.docs) {
      var data = snap.data();
      productsArray.push(data.subCategory);
    }
    setSubCategoryList(productsArray);
  };

  const handleDeleteProductCode = async (codeToDelete) => {
    try {
      const confirmation = window.confirm(
        `Are you sure you want to delete the product code : "${codeToDelete}"?`
      );
      if (!confirmation) return;

      const updatedProductCodes = productCodeList.filter(
        (code) => code !== codeToDelete
      );
      const docRef = doc(fs, "ProductCode", "Dser1KxFY7RvV8DKEoia"); // Use the specific document ID
      await updateDoc(docRef, {
        ProductList: updatedProductCodes,
      });
      setProductCodeList(updatedProductCodes);
      handleToast("Product Code deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting Product Code:", error);
      handleToast("Error deleting Product Code", "error");
    }
  };

  const handleDeleteSampleType = async (typeToDelete) => {
    try {
      const confirmation = window.confirm(
        `Are you sure you want to delete the sample type : "${typeToDelete}"?`
      );
      if (!confirmation) return;

      const updatedSampleTypeList = sampleTypeList.filter(
        (type) => type !== typeToDelete
      );

      const docRef = doc(fs, "SampleType", "4Ffm4xBBb9uK2fc5GPEP");
      await updateDoc(docRef, {
        Sample: updatedSampleTypeList,
      });

      setSampleTypeList(updatedSampleTypeList);
      handleToast("Sample Type deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting Sample Type:", error);
      handleToast("Error deleting Sample Type", "error");
    }
  };

  const handleDeleteCategory = async (categoryToDelete) => {
    try {
      const confirmation = window.confirm(
        `Are you sure you want to delete the category : "${categoryToDelete}"?`
      );
      if (!confirmation) return;

      const updatedCategoryList = categoryList.filter(
        (category) => category !== categoryToDelete
      );

      const docRef = doc(fs, "CategoryList", categoryId);
      await updateDoc(docRef, {
        Category: updatedCategoryList,
      });

      setCategoryList(updatedCategoryList);
      handleToast("Category deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting category:", error);
      handleToast("Error deleting category", "error");
    }
  };

  const handleDeleteSubCategory = async (subCategoryToDelete) => {
    try {
      const confirmation = window.confirm(
        `Are you sure you want to delete the sub category : "${subCategoryToDelete}"?`
      );
      if (!confirmation) return;

      const updatedSubCategoryList = subCategoryList.filter(
        (subCategory) => subCategory !== subCategoryToDelete
      );

      const docRef = doc(fs, "SubCategoryList", subCategoryDocId);
      await updateDoc(docRef, { SubCategory: updatedSubCategoryList });

      setSubCategoryList(updatedSubCategoryList);
      handleToast("Subcategory deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting subcategory:", error);
      handleToast("Error deleting subcategory", "error");
    }
  };

  const handleDeleteProductType = async (typeToDelete) => {
    try {
      const confirmation = window.confirm(
        `Are you sure you want to delete the product type : "${typeToDelete}"?`
      );
      if (!confirmation) return;

      const docRef = doc(fs, "ProductTypeList", productTypeDocId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const updatedTypes = docSnap
          .data()
          .ProductType.filter((type) => type !== typeToDelete);
        await updateDoc(docRef, { ProductType: updatedTypes });
        setProductTypeList(updatedTypes);
        handleToast("Product Type deleted successfully", "success");
      } else {
        console.error("No such document!");
        handleToast("No such document!", "error");
      }
    } catch (error) {
      console.error("Error deleting Product Type:", error);
      handleToast("Error deleting Product Type", "error");
    }
  };

  useEffect(() => {
    const fetchProductCounts = async () => {
      try {
        const productCountsSnapshot = await Promise.all([
          getProductCount("Men"),
          getProductCount("Kid"),
          getProductCount("Women"),
          getProductCount("Fabric"),
          getProductCount("Home-accessories"),
        ]);

        const counts = {};
        productCountsSnapshot.forEach((snapshot, index) => {
          counts[Object.keys(productCounts)[index]] = snapshot.size;
        });
        setProductCounts(counts);
      } catch (error) {
        console.error("Error fetching product counts:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(fs, "users"));
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchPendingUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(fs, "pendingUsers"));
        const pendingUsersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPendingUsers(pendingUsersList);
      } catch (error) {
        console.error("Error fetching pending users:", error);
      }
    };

    const fetchBlockedUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(fs, "blockedUsers"));
        const blockedUsersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlockedUsers(blockedUsersList);
      } catch (error) {
        console.error("Error fetching blocked users:", error);
      }
    };

    const fetchDeclinedUsers = async () => {
      // Fetch declined users
      try {
        const querySnapshot = await getDocs(collection(fs, "declinedUsers")); // Assuming there's a 'declinedUsers' collection
        const declinedUsersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDeclinedUsers(declinedUsersList);
      } catch (error) {
        console.error("Error fetching declined users:", error);
      }
    };

    const fetchActivityLogs = async () => {
      try {
        const activityLogsSnapshot = await fs.collection("ActivityLogs").get();
        const logsData = activityLogsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setActivityLogs(logsData);
      } catch (error) {
        console.error("Error fetching activity logs:", error);
      }
    };

    fetchProductCounts();
    fetchUsers();
    fetchActivityLogs();
    if (activeTab === "UserLogs") {
      fetchPendingUsers();
      fetchBlockedUsers();
      fetchDeclinedUsers();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "DropdownMaster") {
      fetchApparelDropdowns(); // Fetch dropdowns when activeTab is "DropdownMaster"
    }
  }, [activeTab]);

  const fetchApparelDropdowns = async () => {
    try {
      // Assuming you have a collection named "ApparelDropdowns"
      const querySnapshot = await getDocs(collection(fs, "ApparelDropdowns"));
      const dropdownsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setApparelDropdowns(dropdownsList);
    } catch (error) {
      console.error("Error fetching apparel dropdowns:", error);
    }
  };

  const getProductCount = async (category) => {
    const q = query(
      collection(fs, "AparellData"),
      where("category", "==", category.toUpperCase())
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  };

  const handleNavigate = (category) => {
    navigate(`/category/${category}`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTabNavigate = (path) => {
    navigate(path);
  };

  const handleDropdownTabClick = (tab) => {
    setDropdownTab(tab);
  };

  const handleToast = (message, type) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      default:
        toast(message);
        break;
    }
  };

  const approveUser = async (user) => {
    try {
      // Add the user to the 'users' collection with the approved flag set to true
      await setDoc(doc(fs, "users", user.id), {
        ...user,
        approved: true,
      });

      // Delete the user from the 'pendingUsers' collection
      await deleteDoc(doc(fs, "pendingUsers", user.id));
      await deleteDoc(doc(fs, "declinedUsers", user.id));

      // Remove the user from the pendingUsers state
      setPendingUsers(pendingUsers.filter((u) => u.id !== user.id));

      // If the user was previously declined, remove them from the declined users state
      setDeclinedUsers(declinedUsers.filter((u) => u.id !== user.id));

      // Add the user to the approved users state
      setUsers([...users, user]);
      // Notify admin
      handleToast("User approved successfully", "success");
    } catch (error) {
      console.error("Error approving user:", error);
      handleToast("Error approving user", "error");
    }
  };

  const declineUser = async (userId) => {
    try {
      const userToDecline = pendingUsers.find((user) => user.id === userId);
      await setDoc(doc(fs, "declinedUsers", userId), userToDecline); // Store declined user in declinedUsers collection
      await deleteDoc(doc(fs, "pendingUsers", userId));
      setPendingUsers(pendingUsers.filter((u) => u.id !== userId));
      setDeclinedUsers([...declinedUsers, userToDecline]); // Add declined user to declinedUsers state
      // Notify admin
      handleToast("User declined successfully", "success");
    } catch (error) {
      console.error("Error declining user:", error);
      handleToast("Error declining user", "error");
    }
  };

  const blockUser = async (user) => {
    const confirmBlock = window.confirm(
      `Are you sure you want to block the user with email ${user.email}?`
    );

    if (confirmBlock) {
      try {
        // Add the user to the 'blockedUsers' collection
        await setDoc(doc(fs, "blockedUsers", user.id), {
          email: user.email,
          role: user.role,
        });

        // Delete the user from the 'users' collection
        await deleteDoc(doc(fs, "users", user.id));

        // Remove the user from the 'users' state
        setUsers(users.filter((u) => u.id !== user.id));

        // Add the user to the 'blockedUsers' state
        setBlockedUsers([...blockedUsers, user]);

        // Notify admin
        handleToast("User blocked successfully", "success");
      } catch (error) {
        console.error("Error blocking user:", error);
        handleToast("Error blocking user", "error");
      }
    } else {
      handleToast("User block action cancelled", "info");
    }
  };

  const unblockUser = async (user) => {
    const confirmUnblock = window.confirm(
      `Are you sure you want to unblock the user with email ${user.email}?`
    );

    if (confirmUnblock) {
      try {
        // Update the user document in the 'users' collection with approved: true
        await setDoc(doc(fs, "users", user.id), {
          ...user,
          approved: true,
          blocked: false, // Ensure blocked is set to false
        });

        // Delete the user document from the 'blockedUsers' collection
        await deleteDoc(doc(fs, "blockedUsers", user.id));

        // Update the state
        setBlockedUsers(blockedUsers.filter((u) => u.id !== user.id));
        setUsers([...users, { ...user, approved: true, blocked: false }]);
        // Notify admin
        handleToast("User unblocked successfully", "success");
      } catch (error) {
        console.error("Error unblocking user:", error);
        handleToast("Error unblocking user", "error");
      }
    } else {
      handleToast("User unblock action cancelled", "info");
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <AdminHeader />
      <div className="admin-dashboard">
        <div className="tabs-section">
          <div className="tabs">
            <button
              className={activeTab === "Dashboard" ? "active" : ""}
              onClick={() => handleTabClick("Dashboard")}
            >
              Dashboard
            </button>
            <button
              className={activeTab === "UserLogs" ? "active" : ""}
              onClick={() => handleTabClick("UserLogs")}
            >
              User Logs
            </button>
            <button
              className={activeTab === "DropdownMaster" ? "active" : ""}
              onClick={() => handleTabClick("DropdownMaster")}
            >
              Dropdown Master
            </button>
          </div>
        </div>
 {/* <----------------------------------------------------Admin Dashboard section-------------------------------------------------------------> */}
        <div className="dashboard">
          {activeTab === "Dashboard" && (
            <>
              <div className="card men" onClick={() => handleNavigate("Men")}>
                <GiQueenCrown className="icon" />
                <div className="men-image" />
                <div className="bubble-number">{productCounts.Men}</div>
                <h3>Men</h3>
              </div>
              
              <div className="card kids" onClick={() => handleNavigate("Kid")}>
                <FaBaby className="icon" />
                <div className="kids-image" />
                <div className="bubble-number">{productCounts.Kid}</div>
                <h3>Kids</h3>
              </div>
              <div
                className="card women"
                onClick={() => handleNavigate("Women")}
              >
                <GiSharpCrown className="icon" />
                <div className="women-image" />
                <div className="bubble-number">{productCounts.Women}</div>
                <h3>Women</h3>
              </div>
              <div
                className="card fabric"
                onClick={() => handleNavigate("Fabric")}
              >
                <GiWool className="icon" />
                <div className="fabric-image" />
                <div className="bubble-number">{productCounts.Fabric}</div>
                <h3>Fabric</h3>
              </div>
              <div
                className="card home-accessories"
                onClick={() => handleNavigate("Home-accessories")}
              >
                <GiFamilyHouse className="icon" />
                <div className="home-image" />
                <div className="bubble-number">
                  {productCounts["Home-accessories"]}
                </div>
                <h3>Homes</h3>
              </div>
            </>
          )}

{/* <-----------------------------------------------------------------User log section---------------------------------------------------> */}
          {activeTab === "UserLogs" && (
            <div className="user-logs">
              <div className="mini-tabs">
                <button
                  className={miniTab === "ApprovedUsers" ? "active" : ""}
                  onClick={() => setMiniTab("ApprovedUsers")}
                >
                  Approved Users <span className="bubble">{users.length}</span>
                </button>
                <button
                  className={miniTab === "PendingUsers" ? "active" : ""}
                  onClick={() => setMiniTab("PendingUsers")}
                >
                  Pending Users{" "}
                  <span className="bubble">{pendingUsers.length}</span>
                </button>
                <button
                  className={miniTab === "BlockedUsers" ? "active" : ""}
                  onClick={() => setMiniTab("BlockedUsers")}
                >
                  Blocked Users{" "}
                  <span className="bubble">{blockedUsers.length}</span>
                </button>
                <button
                  className={miniTab === "DeclinedUsers" ? "active" : ""}
                  onClick={() => setMiniTab("DeclinedUsers")}
                >
                  Declined Users{" "}
                  <span className="bubble">{declinedUsers.length}</span>
                </button>
                <button
                  className={miniTab === "UserActivities" ? "active" : ""}
                  onClick={() => setMiniTab("UserActivities")}
                >
                  User Activities
                </button>
              </div>
              {miniTab === "ApprovedUsers" && (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      ...users.filter((user) => user.role === "admin"),
                      ...users
                        .filter((user) => user.role !== "admin")
                        .reverse(),
                    ].map((user) => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>
                          <FaBan
                            className="block-icon"
                            onClick={() => blockUser(user)}
                            style={{
                              color: "red", // Adjust the color as needed
                              cursor: "pointer",
                              fontSize: "1.5em", // Adjust the size of the icon
                              transition: "color 0.3s ease", // Add transition effect
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {miniTab === "PendingUsers" && (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingUsers
                      .slice()
                      .reverse()
                      .map((user) => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <div className="icon-container">
                              <FaCheck
                                className="approve-icon"
                                onClick={() => approveUser(user)}
                                style={{
                                  color: "green", // Adjust the color as needed
                                  cursor: "pointer",
                                  fontSize: "1.5em", // Adjust the size of the icon
                                  transition: "color 0.3s ease", // Add transition effect
                                }}
                              />
                              <FaTimes
                                className="decline-icon"
                                onClick={() => declineUser(user.id)}
                                style={{
                                  color: "red", // Adjust the color as needed
                                  cursor: "pointer",
                                  fontSize: "1.5em", // Adjust the size of the icon
                                  transition: "color 0.3s ease", // Add transition effect
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {miniTab === "BlockedUsers" && (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blockedUsers
                      .slice()
                      .reverse()
                      .map((user) => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <button onClick={() => unblockUser(user)}>
                              <FaUnlock style={{ color: "#00c04b" }} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {miniTab === "DeclinedUsers" && (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {declinedUsers
                      .slice()
                      .reverse()
                      .map((user) => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            {/* Replace the button with the FaCheck icon */}
                            <FaCheck
                              onClick={() => approveUser(user)}
                              style={{
                                color: "green", // Adjust the color as needed
                                cursor: "pointer",
                                fontSize: "1.5em", // Adjust the size of the icon
                                transition: "color 0.3s ease", // Add transition effect
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {miniTab === "UserActivities" && (
                <table>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Activity</th>
                      <th>Product Name (Product Code)</th>
                      <th>Date, Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityLogs
                      .sort(
                        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                      ) // Sorting by timestamp
                      .map((log) => (
                        <tr key={log.id}>
                          <td>{log.user}</td>
                          <td>{log.activity}</td>
                          <td>{log.productName}</td>
                          <td>{new Date(log.timestamp).toLocaleString()}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          )}

{/* <---------------------------------------------------Dropdown Master section-------------------------------------------------------> */}
          {activeTab === "DropdownMaster" && (
            <div className="dropdown-logs">
              <div className="dropdown-tabs" style={{ display: "flex" }}>
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    overflowX: "auto",
                    width: "100%",
                  }}
                >
                  <li
                    className={dropdownTab === "ProductCodes" ? "active" : ""}
                  >
                    <button
                      onClick={() => handleDropdownTabClick("ProductCodes")}
                      className={dropdownTab === "ProductCodes" ? "active" : ""}
                    >
                      Product Codes
                    </button>
                  </li>
                  <li className={dropdownTab === "SampleTypes" ? "active" : ""}>
                    <button
                      onClick={() => handleDropdownTabClick("SampleTypes")}
                      className={dropdownTab === "SampleTypes" ? "active" : ""}
                    >
                      Sample Types
                    </button>
                  </li>
                  <li className={dropdownTab === "Categories" ? "active" : ""}>
                    <button
                      onClick={() => handleDropdownTabClick("Categories")}
                      className={dropdownTab === "Categories" ? "active" : ""}
                    >
                      Categories
                    </button>
                  </li>
                  <li
                    className={dropdownTab === "SubCategories" ? "active" : ""}
                  >
                    <button
                      onClick={() => handleDropdownTabClick("SubCategories")}
                      className={
                        dropdownTab === "SubCategories" ? "active" : ""
                      }
                    >
                      Sub Categories
                    </button>
                  </li>
                  <li
                    className={dropdownTab === "ProductTypes" ? "active" : ""}
                  >
                    <button
                      onClick={() => handleDropdownTabClick("ProductTypes")}
                      className={dropdownTab === "ProductTypes" ? "active" : ""}
                    >
                      Product Types
                    </button>
                  </li>
                </ul>
              </div>

              {dropdownTab === "ProductCodes" && (
                <div className="product-codes-container">
                  <div className="input-container">
                    <input
                      type="text"
                      value={newProductCode}
                      onChange={(e) => setNewProductCode(e.target.value)}
                      placeholder="Add new Product Code"
                    />
                    <FaPlus
                      onClick={handleAddProductCode}
                      className="fa-plus"
                    />
                  </div>
                  <div className="product-codes-table-container">
                    <table className="product-codes-table">
                      <thead>
                        <tr>
                          <th>Product Code List</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productCodeList.map((code, index) => (
                          <tr key={index}>
                            <td>{code}</td>
                            <td>
                              <FaTrash
                                onClick={() => handleDeleteProductCode(code)}
                                className="fa-trash"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {dropdownTab === "SampleTypes" && (
                <div className="sample-types-container">
                  <div className="input-container">
                    <input
                      type="text"
                      value={newSampleType}
                      onChange={(e) => setNewSampleType(e.target.value)}
                      placeholder="Add new Sample Type"
                    />
                    <FaPlus onClick={handleAddSampleType} className="fa-plus" />
                  </div>
                  <div className="sample-types-table-container">
                    <table className="sample-types-table">
                      <thead>
                        <tr>
                          <th>Sample Type List</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sampleTypeList.map((type, index) => (
                          <tr key={index}>
                            <td>{type}</td>
                            <td>
                              <FaTrash
                                onClick={() => handleDeleteSampleType(type)}
                                className="fa-trash"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {dropdownTab === "Categories" && (
                 // Used the same name of container to avoid writing excess code of css. Use the same name to maintain consistency
                <div className="sample-types-container"> 
                  <div className="input-container">
                    <input
                      type="text"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      placeholder="Add new Category"
                    />
                    <FaPlus onClick={handleAddCategory} className="fa-plus" />
                  </div>
                  {/* // Used the same name of container to avoid writing excess code of css. Use the same name to maintain consistency  */}
                  <div className="sample-types-table-container">
                    <table className="sample-types-table">
                      <thead>
                        <tr>
                          <th>Category List</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryList.map((category, index) => (
                          <tr key={index}>
                            <td>{category}</td>
                            <td>
                              <FaTrash
                                onClick={() => handleDeleteCategory(category)}
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "1.2em",
                                  transition: "color 0.3s ease",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {dropdownTab === "SubCategories" && (
                 // Used the same name of container to avoid writing excess code of css. Use the same name to maintain consistency 
                <div className="sample-types-container">
                  <div className="input-container">
                    <input
                      type="text"
                      value={newSubCategory}
                      onChange={(e) => setNewSubCategory(e.target.value)}
                      placeholder="Add new Sub Category"
                    />
                    <FaPlus
                      onClick={handleAddSubCategory}
                      className="fa-plus"
                    />
                  </div>
                  {/* // Used the same name of container to avoid writing excess code of css. Use the same name to maintain consistency */}
                  <div className="sample-types-table-container">  
                    <table className="sample-types-table">
                      <thead>
                        <tr>
                          <th>Sub Category List</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subCategoryList.map((subCategory, index) => (
                          <tr key={index}>
                            <td>{subCategory}</td>
                            <td>
                              <FaTrash
                                onClick={() =>
                                  handleDeleteSubCategory(subCategory)
                                }
                                className="fa-trash"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {dropdownTab === "ProductTypes" && (
                 // Used the same name of container to avoid writing excess code of css. Use the same name to maintain consistency 
                <div className="sample-types-container">
                  <div className="input-container">
                    <input
                      type="text"
                      value={newProductType}
                      onChange={(e) => setNewProductType(e.target.value)}
                      placeholder="Add new Product Type"
                    />
                    <FaPlus
                      onClick={handleAddProductType}
                      className="fa-plus"
                    />
                  </div>
                   {/* Used the same name of container to avoid writing excess code of css. Use the same name to maintain consistency */}
                  <div className="sample-types-table-container">
                    <table className="sample-types-table">
                      <thead>
                        <tr>
                          <th>Product Type List</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productTypeList.map((type, index) => (
                          <tr key={index}>
                            <td>{type}</td>
                            <td>
                              <FaTrash
                                onClick={() => handleDeleteProductType(type)}
                                className="fa-trash"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
