import React, { useEffect, useState } from "react";
import { Box, Stack, CardMedia, Card, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fs, auth } from "../../../firebase";
import ProductDetailsTable from "./ProductDetailsTable";
import Qrcode from "../../Qrcode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from "html2canvas";
import "../Components/ProductDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faDownload } from "@fortawesome/free-solid-svg-icons";

export const ProductDetails = ({ productDetails }) => {
  console.log("Product Details Received:", productDetails);
  const navigate = useNavigate();
  const [isQrDialogOpen, setIsQrDialogOpen] = useState(false);
  const [fetchedImageUrls, setFetchedImageUrls] = useState([]);
  console.log(productDetails);
  const {
    uniqueRecordId,
    uniqueId,
    stylecode,
    stylename,
    fobcost,
    producttype,
    sampletype,
    id,
    category,
    subCategory,
    color,
    size,
    washtype,
    season,
    ocnumber,
    orderqty,
    gpt,
    imageUrls,
    fabcode,
    fabricdesc,
    count,
    construction,
    composition,
    gsm,
    cuttable,
  } = productDetails;

  const handlePrintQR = () => {
    // Implement the logic to print the QR code.
    const { stylecode } = productDetails;
    const { composition } = productDetails;
    // This could involve rendering the QR code to a new window and invoking the print dialog.
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><style>h4 { text-align: center; font-size: 20px; font-weight: 1000; color: black; font-family: 'Poppins', sans-serif; }</style></head><body>"
    );
    printWindow.document.write(document.getElementById("qrCode").innerHTML);
    printWindow.document.write(`<h4>${stylecode}</h4>`);
    printWindow.document.write(`<h4>${composition}</h4>`);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const logActivity = async (
    activityDescription,
    productName,
    uniqueRecordId
  ) => {
    try {
      const user = auth.currentUser;

      // Get current date and time
      const currentDate = new Date();

      // Convert current date and time to Indian Standard Time (IST)
      const istDate = currentDate.toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });

      await fs.collection("ActivityLogs").add({
        user: user.email,
        activity: activityDescription,
        productName: `${productName} (${uniqueRecordId})`, // Concatenate product name and unique record id
        timestamp: istDate, // Use IST timestamp
      });

      console.log("Activity logged:", activityDescription);
    } catch (error) {
      console.error("Error logging activity: ", error);
    }
  }

  // const productId = 'someProductId'; // The ID of the product to edit

  const handleEdit = () => {
    if (productDetails && productDetails.uniqueId) {
      navigate(`/EditApparel/${productDetails.uniqueId}`);
    } else {
      console.error("Error: No product ID available for navigation.");
      alert("Unable to edit: Product details missing.");
    }
  };

  const handleDelete = (itemid) => {
    // Confirm with the user before deleting
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (isConfirmed) {
      // Assuming 'id' is the unique identifier for the document to be deleted
      fs.collection("AparellData")
        .doc(itemid)
        .delete()
        .then(() => {
          toast.success("Product successfully deleted!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            onClose: () => navigate(0),
          });
          logActivity("Product deleted", stylename, uniqueRecordId);

          // navigate(`/LandingPage`);
        })
        .catch((error) => {
          toast.error("Error removing product: " + error.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        });
    }
  };

  useEffect(() => {
    if (productDetails && productDetails.id) {
      const fetchImageUrls = async () => {
        const docRef = fs.collection("AparellData").doc(productDetails.id);
        const doc = await docRef.get();

        if (doc.exists) {
          const data = doc.data();
          // Use the new state variable here
          setFetchedImageUrls(data.imageUrls || []);
        } else {
          console.log("No such document!");
        }
      };

      fetchImageUrls();
    }
  }, [productDetails.id]);

  const handleDownloadScreenshot = () => {
    const element = document.getElementById("product-details-container");
    if (!element) {
      alert("No element found to capture.");
      return;
    }

    element.classList.add("screenshot-ready");

    html2canvas(element, {
      onclone: (document) => {
        const clonedElement = document.getElementById(
          "product-details-container"
        );
        const allElements = clonedElement.getElementsByTagName("*");
        for (let i = 0; i < allElements.length; i++) {
          allElements[i].style.transition = "none";
          allElements[i].style.animation = "none";
        }

        const hideElements =
          clonedElement.getElementsByClassName("hide-on-screenshot");
        Array.from(hideElements).forEach((el) => (el.style.display = "none"));
      },
      scale: 3, // Increase the scale for better resolution
      useCORS: true,
      logging: true,
    }).then((canvas) => {
      const fileName = `${stylename.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`; // Create a file name from stylename
      const imageDataUrl = canvas.toDataURL("image/png");
      triggerDownload(imageDataUrl, fileName);

      element.classList.remove("screenshot-ready");
    });
  };

  const triggerDownload = (imgURI, fileName) => {
    const link = document.createElement("a");
    link.href = imgURI;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShareScreenshot = (imageDataUrl) => {
    fetch(imageDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const fileName = `${stylename.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`; // Dynamically generate file name from stylename
        const file = new File([blob], fileName, {
          type: "image/png",
        });
        const filesArray = [file];

        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              files: filesArray,
              title: "Product Details",
              text: `Check out the details of ${stylename}!`,
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => console.error("Sharing failed", error));
        } else {
          console.error("Your system doesn't support sharing files.");
        }
      })
      .catch((err) =>
        console.error("Failed to convert image for sharing", err)
      );
  };

  const handleTakeAndShareScreenshot = () => {
    const element = document.getElementById("product-details-container");
    if (!element) {
      alert("No element found to capture.");
      return;
    }

    element.classList.add("screenshot-ready"); // Apply screenshot-specific styles

    html2canvas(element, {
      onclone: (document) => {
        const clonedElement = document.getElementById(
          "product-details-container"
        );
        // Disable all potential dynamic animations or transitions
        const allElements = clonedElement.getElementsByTagName("*");
        for (let i = 0; i < allElements.length; i++) {
          allElements[i].style.transition = "none";
          allElements[i].style.animation = "none";
        }

        const hideElements =
          clonedElement.getElementsByClassName("hide-on-screenshot");
        for (let i = 0; i < hideElements.length; i++) {
          hideElements[i].style.display = "none"; // This hides the buttons only in the cloned document
        }

        const qrCodeContainer = clonedElement.querySelector("#qrCode");
        if (qrCodeContainer) {
          qrCodeContainer.style.transform = "scale(1)"; // Ensure full size
          qrCodeContainer.style.transformOrigin = "top center";
          qrCodeContainer.style.marginTop = "10px"; // Add top margin for visibility
          qrCodeContainer.style.marginBottom = "10px"; // Add bottom margin to ensure space around QR
        }
      },
      scale: 3, // Adjust scale for higher quality
      useCORS: true,
      logging: true,
    }).then((canvas) => {
      const imageDataUrl = canvas.toDataURL("image/png");
      handleShareScreenshot(imageDataUrl);

      element.classList.remove("screenshot-ready"); // Remove temporary styles
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Box
        id="product-details-container"
        display="flex"
        justifyContent="flex-start"
        sx={{
          bgcolor: "primary.card",
          // paddingTop: 2,
          // paddingLeft: 5,
          // paddingRight: 5,
        }}
      >
        {/* Left side for images */}
        <Box sx={{ width: "60%", display: "flex", flexWrap: "wrap", gap: 2 }}>
          {/* <Stack direction="row" spacing={2} wrap="wrap" justifyContent="flex-start"> */}
          {productDetails.imageUrls?.map((url, index) => (
            // <Box key={index} sx={{ width: "100%", mb: 2 }}>
            <Card key={index} sx={{ width: "40%", mb: 2 }}>
              <CardMedia
                component="img"
                height="400"
                image={url}
                alt={`${producttype}-${index}`}
                title={`${producttype}-${index}`}
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
              />
            </Card>
            // </Box>
          ))}
          {/* </Stack> */}
        </Box>

        {/* Right side for table and QR code */}
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <ProductDetailsTable productDetailsProps={productDetails} />
          <Box sx={{ ml: 3 }}>
            <div id="qrCode" style={{ height: "13em" }}>
              <Box sx={{ padding: "10px" }}>
                <Qrcode
                  uniqueRecordId={uniqueRecordId}
                  stylename={stylename}
                  stylecode={stylecode}
                  fobcost={fobcost}
                  sampletype={sampletype}
                  producttype={producttype}
                  category={category}
                  subCategory={subCategory}
                  color={color}
                  size={size}
                  washtype={washtype}
                  season={season}
                  ocnumber={ocnumber}
                  orderqty={orderqty}
                  fabcode={fabcode}
                  fabricdesc={fabricdesc}
                  count={count}
                  construction={construction}
                  composition={composition}
                  gpt={gpt}
                  gsm={gsm}
                  cuttable={cuttable}
                  showLogo={true}
                />
              </Box>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
                ml: 5,
                mr: 4,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrintQR}
                sx={{ mb: 2, width: "100%" }}
                className="hide-on-screenshot"
              >
                Print QR
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleEdit}
                sx={{ mb: 2, width: "100%" }}
                className="hide-on-screenshot"
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(uniqueId)}
                sx={{ width: "100%" }}
                className="hide-on-screenshot"
              >
                Delete
              </Button>
              <Box display="flex" sx={{ mt: 2 }}>
                <IconButton onClick={handleDownloadScreenshot} color="secondary" sx={{ ml: 5 }} className="hide-on-screenshot">
                  <FontAwesomeIcon icon={faDownload} />
                </IconButton>
                <IconButton onClick={handleTakeAndShareScreenshot} color="primary" sx={{ mr: 2 }} className="hide-on-screenshot">
                  <FontAwesomeIcon icon={faShareAlt} />
                </IconButton>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ProductDetails;
