import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import LottieLogo from './lottie.json';
import { Grid } from '@mui/material';

const Home = () => {
  useEffect(() => {
    document.title = "Sample Inventory";
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: LottieLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={3}>
          <Lottie 
            options={defaultOptions}
            height="150px"
            width="400px"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;