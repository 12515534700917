import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fs } from "../firebase";
import { IndividualProduct, ProductDetails, AddApparel } from "./product";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IndividualProductFilter from "./IndividualProductFilter";
import Pagination from "./Pagination";

const categoryDetails = {
  mensWear: "Men's Wear",
  womensWear: "Women's Wear",
  kidsWear: "Kid's Wear",
  homeAccessories: "Home Accessories",
};

export const Home = () => {
  //   useEffect(() => {

  //     auth.onAuthStateChanged(user => {
  //         if (!user) {
  //             navigate.push('/');
  //         }
  //     })
  // })
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productTypeList, setProductTypeList] = useState([]);
  const [productEditOpen, setProductEditOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const getProducts = async () => {
    const products = await fs.collection("AparellData").get();
    const productsArray = [];
    for (var snap of products.docs) {
      var data = snap.data();
      data.ID = snap.id;
      productsArray.push({
        ...data,
      });
      if (productsArray.length === products.docs.length) {
        setProducts(productsArray);
      }
    }
  };
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = products.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    getProducts();
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setPopupOpen(true);
  };

  const handleDialogClose = () => {
    setPopupOpen(false);
  };

  const handleOnclickOfProduct = (productDetails) => {
    setDialogOpen(true);
    setProductDetails(productDetails);
  };

  // const getProductTypeList =async()=>{
  //   const value=await fs.collection('ProductTypeList').get();

  //   const productsArray=[];
  //   for(var snap of value.docs){
  //     var data=snap.data();
  //    setProductTypeList(data.ProductType
  //       )
  //   }
  // }
  // useEffect(()=>{
  //   getProductTypeList();
  // },[])

  const [spans] = useState([
    { id: "TOPS", text: "TOPS" },
    { id: "SHIRT", text: "SHIRT" },
    { id: "JACKET", text: "JACKET" },
    { id: "DRESS", text: "DRESS" },
    { id: "OUTERWEAR", text: "OUTERWEAR" },
    { id: "SHAKET", text: "SHAKET" },
    { id: "JUMPSUIT", text: "JUMPSUIT" },
    { id: "ROMPER", text: "ROMPER" },
    { id: "TROUSER", text: "TROUSER" },
    { id: "SHORTS", text: "SHORTS" },
    { id: "SET", text: "SET" },
    { id: "JOGGER", text: "JOGGER" },
  ]);
  const [active, setActive] = useState("");
  const [productType, setProductType] = useState("");

  const handleChange = (individualSpan) => {
    setActive(individualSpan.id);
    setProductType(individualSpan.text);
    filterFunction(individualSpan.text);
  };

  const [filteredProducts, setFilteredProducts] = useState([]);

  const filterFunction = (text) => {
    if (products.length >= 1) {
      const filter = products.filter((product) => product.producttype === text);
      setFilteredProducts(filter);
    } else {
      console.log("no products to filter");
    }
  };
  const returntoAllProducts = () => {
    setActive("");
    setProductType("");
    setFilteredProducts([]);
  };

  const handleDialogToClose = () => {
    setDialogOpen(false);
  };

  const handleEditProduct = (productDetails) => {
    setDialogOpen(false);
    setProductEditOpen(true);
  };

  return (
    <React.Fragment>
      <Dialog
        onClose={handleDialogToClose}
        open={dialogOpen}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "79%",
            maxHeight: "100%",
            overflowX: "scroll",
            overflowY: "scroll",
            scrollBehavior: "auto",
          },
        }}
      >
        {productDetails !== undefined || productDetails !== null ? (
          <ProductDetails
            productDetails={productDetails}
            handleEditProduct={handleEditProduct}
          />
        ) : null}
      </Dialog>

      <Dialog open={popupOpen} onClose={handleDialogClose}>
        <DialogTitle>{categoryDetails[selectedCategory]}</DialogTitle>
        <DialogContent>
          <DialogContent>
            Details about {categoryDetails[selectedCategory]}.
          </DialogContent>
        </DialogContent>
      </Dialog>
      <div className="flex flex-row pt-1 fontinter ">
        <Link to="/Mens">
          <button className="ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded ">
            Men's Wear
          </button>
        </Link>
        <Link to="/Women" className="ml-4 mt-3">
          <button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
            Women's Wear
          </button>
        </Link>
        <Link to="/Kidswear" className="ml-4 mt-3">
          <button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
            Kid's Wear
          </button>
        </Link>
        <Link to="/Accessories" className="ml-4 mt-3">
          <button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
            Home & Accessories
          </button>
        </Link>
      </div>

      {productEditOpen ? (
        <AddApparel productDetails={productDetails} />
      ) : (
        <div className="container-fluid filter-products-main-box ">
          <div className="filter-box">
            <h6>FILTERS </h6>
            {spans.map((individualSpan, index) => (
              <span
                key={index}
                id={individualSpan.id}
                onClick={() => handleChange(individualSpan)}
                className={individualSpan.id === active ? active : "deactive"}
              >
                {individualSpan.text}
              </span>
            ))}
          </div>
          {filteredProducts.length > 0 && (
            <div className="pt-4 text-center ">
              <h1 className="">{productType}</h1>
              <a href="javascript:void(0)" onClick={returntoAllProducts}>
                Return to All Products
              </a>
              <div className="products-container ml-3">
                {filteredProducts.map((individualProductFilter) => (
                  <IndividualProductFilter
                    key={individualProductFilter.ID}
                    individualProductFilter={individualProductFilter}
                    handleClick={handleOnclickOfProduct}
                  />
                ))}
              </div>
            </div>
          )}

          {filteredProducts.length < 1 && (
            <>
              <div className="products-container ml-3">
                {currentPosts.length > 0
                  ? currentPosts.map((individualProduct) => (
                      <IndividualProduct
                        key={individualProduct.ID}
                        individualProduct={individualProduct}
                        handleClick={handleOnclickOfProduct}
                      />
                    ))
                  : null}
              </div>
              {currentPosts.length < 1 && (
                <div className="text-center font-bold">ADD Products.......</div>
              )}
            </>
          )}
        </div>
      )}
      <Pagination
        totalPosts={products.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </React.Fragment>
  );
};

export default Home;
