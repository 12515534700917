import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProductCard from "./product/Components/ProductCard";
import { fs } from "../firebase";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import Header from "../components/Header";
import { Circles } from "react-loader-spinner";
import Pagination from "./Pagination";

const SearchResultsPage = () => {
  const navigate = useNavigate();
  const { query } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const q = collection(fs, "AparellData");
        const querySnapshot = await getDocs(q);
        const items = querySnapshot.docs.map((doc) => doc.data());
        // const filteredItems = items.filter(item => item.stylename_lower.includes(query.toLowerCase())); 
        const filteredItems = items.filter(item =>
          item.stylename_lower.includes(query.toLowerCase()) ||
          item.stylecode.toLowerCase().includes(query.toLowerCase())
        );
        setProducts(filteredItems);
        setTotalItemCount(filteredItems.length);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [query, navigate]);

  // Calculate the indices of the first and last items on the current page
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  // Create an array for just the current page's items
  const currentItems = products.slice(firstItemIndex, lastItemIndex);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowDetails(true);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "2% 1% 0 1%",
          textAlign: "center",
        }}
      >
        <div
          className="back-icon_category"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          &lt; Back
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <h1 style={{ margin: "0 1%" }}>Results for : {query}</h1>
          <h2
            style={{
              marginBottom: "1.9%",
              marginRight: "2.5%",
              color: "#007bff",
              fontWeight: 600,
            }}
          >
            Total Items: {totalItemCount}
          </h2>
        </div>
        <div style={{ width: 48 }} />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#007bff"
            ariaLabel="loading"
          />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "60vh",
              gap: "20px",
              flexWrap: "wrap",
              padding: "30px 2% 0 2%",
            }}
          >
            {currentItems.length === 0 ? (
              <h1 style={{ marginRight: "3%" }}>Sorry, No Products found !!</h1>
            ) : (
              currentItems.map((product, index) => (
                <div
                  key={index}
                  onClick={() => handleProductClick(product)}
                >
                  <ProductCard item={product} />
                </div>
              ))
            )}
          </div>
          {totalItemCount > itemsPerPage && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px", // Add some margin to separate pagination from products
              }}
            >
              <Pagination
                totalPosts={totalItemCount}
                postsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResultsPage;
