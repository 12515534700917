import React,{useState, useEffect} from 'react'
import {fs} from '../../../firebase'
import { useRef } from 'react';
import {FlatButton} from '../../commonComponents'
import { AddProduct } from '..';



export const ProductDetailsTable = ({productDetailsProps}) => {
   console.log(productDetailsProps)
    const {category, subCategory, color, fobcost,gsm,cuttable,count,comments,fabricdesc, composition, 
      construction, fabcode,stylecode,stylename,producttype,sampletype,size, washtype, 
      season, ocnumber, orderqty, gpt, uniqueId, uniqueRecordId} = productDetailsProps
        const ref=useRef()
        const [fetchrecord,setFetchrecord]=useState()

        const tableStyle = {
         width: '100%',
         fontSize: '1.2rem', 
       };
     
       const cellStyle = {
         padding: '5px', 
       };

  return (

   <div  >
      <div >

    <table style={tableStyle}>
      
      <tbody >
      <tr key={1} >
          <td style={cellStyle}>{'DOCUMENT ID'}</td>
           <td style={cellStyle}>{uniqueId}</td>
          </tr>
          <tr key={1} >
          <td style={cellStyle}>{'PRODUCT CODE'}</td>
           <td style={cellStyle}>{uniqueRecordId}</td>
          </tr>
          <tr key={1} >
          <td style={cellStyle}>{'STYLE NAME'}</td>
           <td style={cellStyle}>{stylename}</td>
          </tr>
          <tr key={2}>
           <td style={cellStyle}>{'STYLE CODE'}</td>
           <td style={cellStyle}>{stylecode}</td>
        </tr>
        <tr key={2}>
           <td style={cellStyle}>{'SAMPLE TYPE'}</td>
           <td style={cellStyle}>{sampletype}</td>
        </tr>
        <tr key={4}>
           <td style={cellStyle}>{'PRODUCT TYPE'}</td>
           <td style={cellStyle}>{producttype}</td>
        </tr>
        <tr key={5}>
           <td style={cellStyle}>{'CATEGORY'}</td>
           <td style={cellStyle}>{category}</td>
        </tr>
        <tr key={5}>
           <td style={cellStyle}>{'SUB CATEGORY'}</td>
           <td style={cellStyle}>{subCategory}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'SIZE'}</td>
           <td style={cellStyle}>{size}</td>
        </tr>
        <tr key={7}>
           <td style={cellStyle}>{'COLOUR'}</td>
           <td style={cellStyle}>{color}</td>
        </tr>
        <tr key={8}>
           <td style={cellStyle}>{'WASHTYPE'}</td>
           <td style={cellStyle}>{washtype}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'SEASON'}</td>
           <td style={cellStyle}>{season}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'FOB COST'}</td>
           <td style={cellStyle}>{fobcost}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'OC NUMBER'}</td>
           <td style={cellStyle}>{ocnumber}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'ORDER QTY'}</td>
           <td style={cellStyle}>{orderqty}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'GPT'}</td>
           <td style={cellStyle}>{gpt}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'COMMENTS'}</td>
           <td style={cellStyle}>{comments}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'FAB CODE'}</td>
           <td style={cellStyle}>{fabcode}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'FAB DESC'}</td>
           <td style={cellStyle}>{fabricdesc}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'COUNT'}</td>
           <td style={cellStyle}>{count}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'CONSTRUCTION'}</td>
           <td style={cellStyle}>{construction}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'COMPOSITION'}</td>
           <td style={cellStyle}>{composition}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'GSM'}</td>
           <td style={cellStyle}>{gsm}</td>
        </tr>
        <tr key={6}>
           <td style={cellStyle}>{'CUTTABLE WIDTH'}</td>
           <td style={cellStyle}>{cuttable}</td>
        </tr>
       
     </tbody>
    </table>
</div>

</div>
  
  )
}
 export default ProductDetailsTable;