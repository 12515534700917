import React from 'react'
import { FormControl, Button } from '@mui/material'

export const FlatButton = ({label, onClick}) => {
  return (
    <FormControl >
      <Button variant="contained" size="medium" sx={{
        backgroundColor: 'alpha.light'
      }} onClick={onClick}>{label}</Button>
    </FormControl>
  )
}
export default FlatButton