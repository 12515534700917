import React from 'react'
import {Box, Stack, CardMedia, Card} from '@mui/material'
import ProductDetailsTable1 from './ProductDetailsTable1'
import {FlatButton} from '../pages/commonComponents/FlatButton'

export const ProductDetails1 = ({productDetails1, handleEditProduct}) => {

  const {fabricsup,imageUrls, materialcd, count, construction, composition, gsm, finish,
    cuttable, weave, price, remarks,fabrictype,moq,submitted,fpt,leadtime,uniqueRecordId}=productDetails1

  return (
    <React.Fragment>

    <Box
      display="flex"
      justifyContent="flex-start"
      sx={{
        bgcolor: 'primary.card',
        paddingTop: 2,
        paddingLeft: 5

      }}
    >
      <FlatButton label='EDIT' onClick={handleEditProduct}/>
    </Box>

<Box sx={{paddingLeft: 5, paddingBottom: 4, paddingRight: 35, paddingTop: 2}}>
  
<Box component="span" m={1} display="flex" justifyContent="space-between" >

<Stack direction='column' spacing={2}>
{
imageUrls.map((url, Index)=>{
  return(

    <Card
    raised
    sx={{
      maxWidth: 200,
      maxHeight: 250,
      margin: "0 auto",
      padding: "0.1em",
    }}
  >
    <CardMedia
      component="img"
      height="250"
      image={url}
      alt={`${fabricsup}-${Index}`}
      title={`${fabricsup}-${Index}`}
      sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
    />
  
  </Card>
  )
})
}
</Stack>

<Box>
<ProductDetailsTable1 productDetailsProps= {productDetails1}/>
</Box>
</Box>
</Box>
    
</React.Fragment>
  )
}

export default ProductDetails1;