import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { fs } from "../firebase";
import "./GenderCategory.css";
import Pagination from "./Pagination";
import ProductCard from "../pages/product/Components/ProductCard";
import Header from "../components/Header";
import { Circles } from 'react-loader-spinner';

const mapSubCategoryToTypes = {
  "top-wear": [
    "TOPS",
    "SHIRT",
    "OUTERWEAR",
    "JACKET",
    "SHAKET",
    "DRESS",
    "JUMPSUIT",
    "ROMPER",
  ],
  "bottom-wear": ["JUMPSUIT", "TROUSER", "SHORTS", "SET", "JOGGER"],
  "lounge-wear": ["LOUNGEWEAR"],
  "denim-wear": [
    "TOPS",
    "SHIRT",
    "OUTERWEAR",
    "JACKET",
    "SHAKET",
    "DRESS",
    "JUMPSUIT",
    "LOUNGEWEAR",
    "ROMPER",
    "TROUSER",
    "SHORTS",
    "SET",
    "JOGGER",
  ],
  // Ensure these match exactly with your Firestore document's ProductType field values
};

const mapSubCategoryToReadableName = {
  "top-wear": "Tops",
  "bottom-wear": "Bottoms",
  "lounge-wear": "Loungewear",
  "denim-wear": "Denim",
};

const GenderCategoryPage = () => {
  const { category, subCategory } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);

  const handleProductTypeSelect = (type) => {
    setSelectedProductType(type);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let productTypes = mapSubCategoryToTypes[subCategory] || [];
      if (selectedProductType) {
        productTypes = [selectedProductType];
      }
  
      setItems([]);
      setTotalItemCount(0);
  
      const queries = productTypes.map(type => {
        const baseQuery = query(
          collection(fs, "AparellData"),
          where("category", "==", category.toUpperCase()),
          where("producttype", "==", type)
        );
  
        // Modify the query based on whether it's the 'denim-wear' subcategory or not
        if (subCategory === "denim-wear") {
          // For 'denim-wear', fetch only items where 'isDenim' is true
          return query(baseQuery, where("isDenim", "==", true));
        } else {
          // For other categories, fetch only items where 'isDenim' is either false or not set
          return query(baseQuery, where("isDenim", "==", false));
        }
      });
  
      const results = await Promise.all(queries.map(q => getDocs(q)));
      const fetchedItems = results.flatMap(querySnapshot => querySnapshot.docs.map(doc => doc.data()));
  
      setItems(fetchedItems);
      setTotalItemCount(fetchedItems.length);
      setLoading(false);
    }
  
    fetchData();
  }, [category, subCategory, selectedProductType]);

  const currentItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const shouldDisplayPagination = items.length > itemsPerPage;

  return (
    <div>
      <Header />
      <div className="gendercategory-header">
        <div className="back-icon_gendercategory" onClick={() => navigate(-1)}>
          &lt; Back
        </div>

        <h1>{category.charAt(0).toUpperCase() + category.slice(1)}'s Wear - {mapSubCategoryToReadableName[subCategory]}</h1>
        <h2 className="total">Total Items: {totalItemCount}</h2>
      </div>
      {/* Render buttons for each product type in the current sub-category */}
      <div className="sub-category-buttons">
  {mapSubCategoryToTypes[subCategory]?.map((type) => (
    <button
      key={type}
      onClick={() => handleProductTypeSelect(type)}
      className={`ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded ${
        selectedProductType === type ? 'active-product-type-button' : ''
      }`}
    >
      {type}
    </button>
  ))}
  <button
    onClick={() => handleProductTypeSelect(null)} // This will set the state to null, effectively deselecting any product type
    className={`ml-2 mt-3 bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded ${
      selectedProductType === null ? 'active-product-type-button' : ''
    }`}
  >
    Show All
  </button>
</div>
      <div className="products-grid">
      {loading ? ( // Check if loading is true
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <Circles
              height={80}
              width={80}
              color="#007bff"
              ariaLabel="loading"
            />
          </div>
        ) : currentItems.length > 0 ? (
          currentItems.map((item, index) => (
            <ProductCard key={index} item={item} />
          ))
        ) : (
          <h1>No items found for this Sub-Category !!</h1>
        )}
      </div>
      {shouldDisplayPagination && (
        <Pagination
          totalPosts={items.length}
          postsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default GenderCategoryPage;
