import React, { useEffect } from 'react'
import { Button, Menu, MenuItem, Box, Typography } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'



export const ProductDropDown = ({menuValues, onChange, dropDownText, label}) => {


  const [buttonText, setButtonText] = React.useState(dropDownText)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  useEffect(()=>{
    setButtonText(dropDownText)
  }, [menuValues])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (e) => {
    menuValues.map(item => {
      if (e.currentTarget.textContent === item) {
        changeButtonText(item)
      }
      return null
    })
    handleClose()
  }

  const changeButtonText = (text) => {
    console.log('manju--onclose')
    if (text !== buttonText) {
      console.log('manju--text changes')
      onChange(text)
    }
    console.log('manju--setText')
    console.log(text)
    setButtonText(text)
  }

  return (
    <React.Fragment>
      <Box>
    <Typography variant='body1' sx={{fontFamily:'serif'}}>{label}</Typography>
    
      <Button
        id='dropdown-menu-button'
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          fontFamily: 'button.medium',
          border: '1px solid black'
        }}
        size='small' variant='contained' endIcon={<KeyboardArrowDown />}>
        {buttonText}
      </Button>
      <Menu id='dropdown-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": 'dropdown-menu-button'
        }}>
        {
          menuValues.map(item => {
            return (
              <MenuItem onClick={handleMenuItemClick}>{item}</MenuItem>
            )
          })
        }
      </Menu>
      </Box>
    </React.Fragment>
  )
}

export default ProductDropDown