import React,{useState, useEffect} from 'react'

import { useRef } from 'react';

export const ProductDetailsTable1 = ({productDetailsProps}) => {
    const {gsm,count, composition, construction,uniqueRecordId,fabricsup,
      materialcd, cuttable, finish, weave,fabrictype,price,moq,leadtime,submitted, fpt,remarks,} = productDetailsProps
        const ref=useRef()

  return (
   <div  >
      <div >
    <table className= ' w-72 tablesize fontinter'>
      <thead>
      </thead>
      <tbody >         
      <tr key={6}>
           <td className='tablesize'>{'FAB CODE'}</td>
           <td className='tablesize'>{uniqueRecordId}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Fabric Material(T1)'}</td>
           <td className='tablesize'>{fabricsup}</td>
        </tr> 
      
        <tr key={6}>
           <td className='tablesize'>{'T2 Material Code'}</td>
           <td className='tablesize'>{materialcd}</td>
        </tr>
       
        <tr key={6}>
           <td className='tablesize'>{'COUNT'}</td>
           <td className='tablesize'>{count}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'CONSTRUCTION'}</td>
           <td className='tablesize'>{construction}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'COMPOSITION'}</td>
           <td className='tablesize'>{composition}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'GSM'}</td>
           <td className='tablesize'>{gsm}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Cuttable-width'}</td>
           <td className='tablesize'>{cuttable}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Finish'}</td>
           <td className='tablesize'>{finish}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Weave'}</td>
           <td className='tablesize'>{weave}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Fabric-Type'}</td>
           <td className=''>{fabrictype}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Price'}</td>
           <td className='tablesize'>{price}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'MOQ'}</td>
           <td className='tablesize'>{moq}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'LeadTime'}</td>
           <td className='tablesize'>{leadtime}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Submitted to'}</td>
           <td className=''>{submitted}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'FPT'}</td>
           <td className='tablesize'>{fpt}</td>
        </tr>
        <tr key={6}>
           <td className='tablesize'>{'Remarks'}</td>
           <td className='tablesize'>{remarks}</td>
        </tr>
     </tbody>
    </table>
</div>

</div>
  
  )
}
 export default ProductDetailsTable1;