// import React, { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// import { fs } from "../firebase";
// import { IndividualProduct, ProductDetails, AddApparel } from "./product";
// import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
// import IndividualProductFilter from "./IndividualProductFilter";
// import Pagination from "./Pagination";
// import "./Mens.css"
// import Header from "../components/Header";

// const categoryDetails = {
//   mensWear: "Men's Wear",
//   womensWear: "Women's Wear",
//   kidsWear: "Kid's Wear",
//   // homeAccessories: "Home Accessories",
// };

// export const Mens = () => {
//   //   useEffect(() => {

//   //     auth.onAuthStateChanged(user => {
//   //         if (!user) {
//   //             navigate.push('/');
//   //         }
//   //     })
//   // })
//   const [currentPage, setCurrentPage] = useState(1);
//   const [postsPerPage, setPostsPerPage] = useState(20);
//   const [products, setProducts] = useState([]);
//   const [productDetails, setProductDetails] = useState();
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [productTypeList, setProductTypeList] = useState([]);
//   const [productEditOpen, setProductEditOpen] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [popupOpen, setPopupOpen] = useState(false);

//   const getProducts = async () => {
//     const products = await fs.collection("AparellData").get();
//     const productsArray = [];
//     for (var snap of products.docs) {
//       var data = snap.data();
//       data.ID = snap.id;
//       productsArray.push({
//         ...data,
//       });
//       if (productsArray.length === products.docs.length) {
//         setProducts(productsArray);
//       }
//     }
//   };
//   const lastPostIndex = currentPage * postsPerPage;
//   const firstPostIndex = lastPostIndex - postsPerPage;
//   const currentPosts = products.slice(firstPostIndex, lastPostIndex);

//   useEffect(() => {
//     getProducts();
//   }, []);

//   const handleCategorySelect = (category) => {
//     setSelectedCategory(category);
//     setPopupOpen(true);
//   };

//   const handleDialogClose = () => {
//     setPopupOpen(false);
//   };

//   const handleOnclickOfProduct = (productDetails) => {
//     setDialogOpen(true);
//     setProductDetails(productDetails);
//   };

//   // const getProductTypeList =async()=>{
//   //   const value=await fs.collection('ProductTypeList').get();

//   //   const productsArray=[];
//   //   for(var snap of value.docs){
//   //     var data=snap.data();
//   //    setProductTypeList(data.ProductType
//   //       )
//   //   }
//   // }
//   // useEffect(()=>{
//   //   getProductTypeList();
//   // },[])

//   const [spans] = useState([
//     { id: "TOPS", text: "TOPS" },
//     { id: "SHIRT", text: "SHIRT" },
//     { id: "JACKET", text: "JACKET" },
//     { id: "DRESS", text: "DRESS" },
//     { id: "OUTERWEAR", text: "OUTERWEAR" },
//     { id: "SHAKET", text: "SHAKET" },
//     { id: "JUMPSUIT", text: "JUMPSUIT" },
//     { id: "ROMPER", text: "ROMPER" },
//     { id: "TROUSER", text: "TROUSER" },
//     { id: "SHORTS", text: "SHORTS" },
//     { id: "SET", text: "SET" },
//     { id: "JOGGER", text: "JOGGER" },
//   ]);
//   const [active, setActive] = useState("");
//   const [productType, setProductType] = useState("");

//   const handleChange = (individualSpan) => {
//     setActive(individualSpan.id);
//     setProductType(individualSpan.text);
//     filterFunction(individualSpan.text);
//   };

//   const [filteredProducts, setFilteredProducts] = useState([]);

//   const filterFunction = (text) => {
//     if (products.length >= 1) {
//       const filter = products.filter((product) => product.producttype === text);
//       setFilteredProducts(filter);
//     } else {
//       console.log("no products to filter");
//     }
//   };
//   const returntoAllProducts = () => {
//     setActive("");
//     setProductType("");
//     setFilteredProducts([]);
//   };

//   const handleDialogToClose = () => {
//     setDialogOpen(false);
//   };

//   const handleEditProduct = (productDetails) => {
//     setDialogOpen(false);
//     setProductEditOpen(true);
//   };

//   return (
//     <React.Fragment>
//       <Dialog
//         onClose={handleDialogToClose}
//         open={dialogOpen}
//         PaperProps={{
//           sx: {
//             width: "100%",
//             maxWidth: "70%",
//             overflowX: "scroll",
//             overflowY: "scroll",
//             scrollBehavior: "auto",
//           },
//         }}
//       >
//         {productDetails !== undefined || productDetails !== null ? (
//           <ProductDetails
//             productDetails={productDetails}
//             handleEditProduct={handleEditProduct}
//           />
//         ) : null}
//       </Dialog>

//       <Dialog open={popupOpen} onClose={handleDialogClose}>
//         <DialogTitle>{categoryDetails[selectedCategory]}</DialogTitle>
//         <DialogContent>
//           <DialogContent>
//             Details about {categoryDetails[selectedCategory]}.
//           </DialogContent>
//         </DialogContent>
//       </Dialog>
//       <div>
//         <Box>
//         <header className="header-gradient">Men's Wear</header>
//         </Box>
//         <Box display="flex" justifyContent="space-around" width="50%" marginX="auto">
//         <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded ">
//           Top Wear
//         </button>
//         <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//           Bottom Wear
//         </button>
//         <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//           Lounge Wear
//         </button>
//         <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//           Denim Wear
//         </button>
//         </Box>
//       </div>

//       {productEditOpen ? (
//         <AddApparel productDetails={productDetails} />
//       ) : (
//         <div className="container-fluid filter-products-main-box ">
//           <div className="filter-box">
//             <h6>FILTERS </h6>
//             {spans.map((individualSpan, index) => (
//               <span
//                 key={index}
//                 id={individualSpan.id}
//                 onClick={() => handleChange(individualSpan)}
//                 className={individualSpan.id === active ? active : "deactive"}
//               >
//                 {individualSpan.text}
//               </span>
//             ))}
//           </div>
//           {filteredProducts.length > 0 && (
//             <div className="pt-4 text-center ">
//               <h1 className="">{productType}</h1>
//               <a href="javascript:void(0)" onClick={returntoAllProducts}>
//                 Return to All Products
//               </a>
//               <div className="products-container ml-3">
//                 {filteredProducts.map((individualProductFilter) => (
//                   <IndividualProductFilter
//                     key={individualProductFilter.ID}
//                     individualProductFilter={individualProductFilter}
//                     handleClick={handleOnclickOfProduct}
//                   />
//                 ))}
//               </div>
//             </div>
//           )}

//           {filteredProducts.length < 1 && (
//             <>
//               <div className="products-container ml-3">
//                 {currentPosts.length > 0
//                   ? currentPosts.map((individualProduct) => (
//                       <IndividualProduct
//                         key={individualProduct.ID}
//                         individualProduct={individualProduct}
//                         handleClick={handleOnclickOfProduct}
//                       />
//                     ))
//                   : null}
//               </div>
//               {/* {currentPosts.length < 1 && (
//                 <div className="text-center font-bold">ADD Products.......</div>
//               )} */}
//             </>
//           )}
//         </div>
//       )}
//       <Pagination
//         totalPosts={products.length}
//         postsPerPage={postsPerPage}
//         setCurrentPage={setCurrentPage}
//         currentPage={currentPage}
//       />
//     </React.Fragment>
//   );
// };

// export default Mens;

// <----------------------------------------------------------------Trial Code-------------------------------------------------------------------->

// import React, { useEffect, useState } from "react";
// import { fs } from "../firebase";
// import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
// import IndividualProductFilter from "./IndividualProductFilter";
// import Pagination from "./Pagination";
// import "./Mens.css";
// import Header from "../components/Header";

// // Assuming your products have a "gender" field and a "productType" field
// const categoryToGenderMapping = {
//   mensWear: "mens",
//   womensWear: "womens",
//   kidsWear: "kids",
//   homeAccessories: "home",
// };

// const categoryDetails = {
//   mensWear: "Top Wear",
//   womensWear: "Bottom Wear",
//   kidsWear: "Lounge Wear",
//   homeAccessories: "Denim Wear",
// };

// const Mens = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [postsPerPage] = useState(20);
//   const [allProducts, setAllProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [productDetails, setProductDetails] = useState(null);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [popupOpen, setPopupOpen] = useState(false);
//   const [productType, setProductType] = useState("");
//   const [active, setActive] = useState("");

//   const spans = [
//     { id: "TOPS", text: "TOPS" },
//     { id: "SHIRT", text: "SHIRT" },
//     { id: "JACKET", text: "JACKET" },
//     { id: "DRESS", text: "DRESS" },
//     { id: "OUTERWEAR", text: "OUTERWEAR" },
//     { id: "SHAKET", text: "SHAKET" },
//     { id: "JUMPSUIT", text: "JUMPSUIT" },
//     { id: "ROMPER", text: "ROMPER" },
//     { id: "TROUSER", text: "TROUSER" },
//     { id: "SHORTS", text: "SHORTS" },
//     { id: "SET", text: "SET" },
//     { id: "JOGGER", text: "JOGGER" },
//   ];

//   useEffect(() => {
//     const getProducts = async () => {
//       const snapshot = await fs.collection("AparellData").get();
//       const productsArray = snapshot.docs.map((doc) => ({
//         ID: doc.id,
//         ...doc.data(),
//       }));
//       setAllProducts(productsArray);
//       setFilteredProducts(productsArray); // Initialize filtered products as all products
//     };
//     getProducts();
//   }, []);

//   useEffect(() => {
//     let filtered = allProducts;

//     if (selectedCategory) {
//       filtered = filtered.filter(
//         (product) =>
//           product.gender === categoryToGenderMapping[selectedCategory]
//       );
//     }

//     if (productType) {
//       filtered = filtered.filter(
//         (product) => product.productType === productType
//       );
//     }

//     setFilteredProducts(filtered);
//   }, [selectedCategory, productType, allProducts]);

//   const handleChange = (individualSpan) => {
//     setActive(individualSpan.id);
//     setProductType(individualSpan.text);
//   };

//   const currentPosts = filteredProducts.slice(
//     (currentPage - 1) * postsPerPage,
//     currentPage * postsPerPage
//   );

//   return (
//     <React.Fragment>
//       <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
//         <DialogTitle>Product Details</DialogTitle>
//         <DialogContent>
//           {/* Render your productDetails here */}
//           {productDetails && <div>{productDetails.name}</div>}
//         </DialogContent>
//       </Dialog>

//       <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
//         <DialogTitle>{categoryDetails[selectedCategory]}</DialogTitle>
//         <DialogContent>
//           Details about {categoryDetails[selectedCategory]}.
//         </DialogContent>
//       </Dialog>

//       <div>
//         {" "}
//         <Box>
//           <header className="header-gradient">Men's Wear</header>
//         </Box>
//         <Box
//           display="flex"
//           justifyContent="space-around"
//           width="50%"
//           marginX="auto"
//         >
//           <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded ">
//             Top Wear
//           </button>
//           <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//             Bottom Wear
//           </button>
//           <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//             Lounge Wear
//           </button>
//           <button className=" mt-3 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded">
//             Denim Wear
//           </button>
//         </Box>
//       </div>
//       {/* Example buttons to select categories - adjust as necessary */}
//       <Box display="flex" justifyContent="space-around" marginX="auto">
//         {Object.keys(categoryDetails).map((category) => (
//           <button
//             key={category}
//             onClick={() => setSelectedCategory(category)}
//             className="category-button"
//           >
//             {categoryDetails[category]}
//           </button>
//         ))}
//       </Box>

//       <div className="filter-box">
//         {spans.map((individualSpan, index) => (
//           <span
//             key={index}
//             className={individualSpan.id === active ? "active" : "deactive"}
//             onClick={() => handleChange(individualSpan)}
//           >
//             {individualSpan.text}
//           </span>
//         ))}
//       </div>

//       <div className="products-container">
//         {currentPosts.map((product) => (
//           <IndividualProductFilter
//             key={product.ID}
//             individualProductFilter={product}
//             handleClick={() => {
//               setProductDetails(product);
//               setDialogOpen(true);
//             }}
//           />
//         ))}
//       </div>

//       <Pagination
//         totalPosts={filteredProducts.length}
//         postsPerPage={postsPerPage}
//         setCurrentPage={setCurrentPage}
//         currentPage={currentPage}
//       />
//     </React.Fragment>
//   );
// };

// export default Mens;

// <----------------------------------------Important Code---------------------------------------------------------->

// import React, { useEffect, useState } from "react";
// import { fs } from "../firebase";
// import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
// import IndividualProductFilter from "./IndividualProductFilter";
// import Pagination from "./Pagination";
// import "./Mens.css";
// // import Header from "../components/Header";

// const categoryToProductTypeMapping = {
//   TopWear: ["TOPS", "SHIRT", "JACKET", "SHAKET", "DRESS", "JUMPSUIT", "ROMPER"],
//   BottomWear: ["JUMPSUIT", "TROUSER", "SHORTS", "SET", "JOGGER"],
//   LoungeWear: ["LONGEWEAR"],
//   DenimWear: ["TOPS", "SHIRTS", "OUTERWEAR", "JACKET", "SHAKET", "DRESS", "JUMPSUIT", "ROMPER", "TROUSER", "SHORTS", "LONGEWEAR", "SET", "JOGGER" ],
// };

// const Mens = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [postsPerPage] = useState(20);
//   const [allProducts, setAllProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedProductTypes, setSelectedProductTypes] = useState([]);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [productDetails, setProductDetails] = useState(null);

//   useEffect(() => {
//     const getProducts = async () => {
//       const snapshot = await fs.collection("AparellData").get();
//       const productsArray = snapshot.docs.map(doc => ({ ID: doc.id, ...doc.data() }));
//       setAllProducts(productsArray);
//       filterProducts();
//     };
//     getProducts();
//   }, []);

//   useEffect(() => {
//     setSelectedProductTypes(categoryToProductTypeMapping[selectedCategory] || []);
//     filterProducts();
//   }, [selectedCategory, allProducts]);

//   useEffect(() => {
//     filterProducts();
//   }, [selectedProductTypes]);

//   const filterProducts = () => {
//     let filtered = allProducts;

//     if (selectedCategory && selectedProductTypes.length > 0) {
//       filtered = filtered.filter(product => selectedProductTypes.includes(product.productType));
//     }

//     setFilteredProducts(filtered);
//   };

//   // Single Select

//   const handleProductTypeChange = (type) => {
//     // If the same type is clicked again, clear the filter (optional behavior)
//     if (selectedProductType === type) {
//       setSelectedProductType('');
//     } else {
//       setSelectedProductType(type);
//     }
//   };
  
//   useEffect(() => {
//     // Filter products based on the selected product type
//     const filtered = selectedProductType
//       ? allProducts.filter(product => product.productType === selectedProductType)
//       : allProducts;
//     setFilteredProducts(filtered);
//   }, [selectedProductType, allProducts]);

//   // Multi Select

//   // const handleProductTypeChange = (type) => {
//   //   setSelectedProductTypes(prevTypes => 
//   //     prevTypes.includes(type) 
//   //       ? prevTypes.filter(t => t !== type) // Remove type if already selected
//   //       : [...prevTypes, type] // Add type if not already selected
//   //   );
//   // };
  
//   // useEffect(() => {
//   //   // Filter products based on the selected product types array
//   //   const filtered = selectedProductTypes.length > 0
//   //     ? allProducts.filter(product => selectedProductTypes.includes(product.productType))
//   //     : allProducts;
//   //   setFilteredProducts(filtered);
//   // }, [selectedProductTypes, allProducts]);

//   const currentPosts = filteredProducts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

//   return (
//     <React.Fragment>
      
//       <Box className="header-gradient">Men's Wear</Box>

//       <Box display="flex" justifyContent="space-between" marginX="auto" sx={{ maxWidth: 720, mx: "auto", p: 2 }}>
//         {Object.keys(categoryToProductTypeMapping).map(category => (
//           <button
//             key={category}
//             onClick={() => setSelectedCategory(category)}
//             className={`category-button ${selectedCategory === category ? "selected" : ""}`}
//           >
//             {category}
//           </button>
//         ))}
//       </Box>

//       {/* Filter section for product types */}
//       {selectedCategory && (
//         <Box display="flex" justifyContent="center" flexWrap="wrap" sx={{ my: 2 }}>
//           {categoryToProductTypeMapping[selectedCategory].map(type => (
//             <button
//               key={type}
//               onClick={() => handleProductTypeChange(type)}
//               className={`product-type-button ${selectedProductTypes.includes(type) ? "active" : ""}`}
//               style={{ margin: '8px' }} // Inline styling example
//             >
//               {type}
//             </button>
//           ))}
//         </Box>
//       )}

//       <div className="products-container">
//         {currentPosts.map(product => (
//           <IndividualProductFilter
//             key={product.ID}
//             individualProductFilter={product}
//             handleClick={() => {
//               setProductDetails(product);
//               setDialogOpen(true);
//             }}
//           />
//         ))}
//       </div>

//       <Pagination
//         totalPosts={filteredProducts.length}
//         postsPerPage={postsPerPage}
//         setCurrentPage={setCurrentPage}
//         currentPage={currentPage}
//       />

//       <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
//         <DialogTitle>Product Details</DialogTitle>
//         <DialogContent>
//           {productDetails && <div>{productDetails.name}</div>}
//         </DialogContent>
//       </Dialog>
//     </React.Fragment>
//   );
// };

// export default Mens;


// <-----------------------------------------------------------------Last resort-------------------------------------------------------------------------------------->

// import React, { useEffect, useState } from "react";
// import { fs } from "../firebase";
// import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
// import IndividualProductFilter from "./IndividualProductFilter";
// import Pagination from "./Pagination";
// import "./Mens.css";

// // Sub-category mapping to product types
// const subCategoryMapping = {
//   TopWear: ["TOPS", "SHIRT", "JACKET", "SHAKET", "DRESS", "JUMPSUIT", "ROMPER"],
//   BottomWear: ["JUMPSUIT", "TROUSER", "SHORTS", "SET", "JOGGER"],
//   LoungeWear: ["LOUNGEWEAR"],
//   DenimWear: ["TOPS", "SHIRTS", "OUTERWEAR", "JACKET", "SHAKET", "DRESS", "JUMPSUIT", "ROMPER", "TROUSER", "SHORTS", "LOUNGEWEAR", "SET", "JOGGER"],
// };

// const Mens = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [postsPerPage] = useState(20);
//   const [allProducts, setAllProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [selectedSubCategory, setSelectedSubCategory] = useState("");
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [productDetails, setProductDetails] = useState(null);

//   useEffect(() => {
//     const getProducts = async () => {
//       const snapshot = await fs.collection("AparellData").get();
//       const productsArray = snapshot.docs.map(doc => ({ ID: doc.id, ...doc.data() }));
//       setAllProducts(productsArray);
//       setFilteredProducts(productsArray);
//     };
//     getProducts();
//   }, []);

//   useEffect(() => {
//     filterProductsBySubCategory();
//   }, [selectedSubCategory, allProducts]);

//   const filterProductsBySubCategory = () => {
//     if (selectedSubCategory) {
//       const productTypes = subCategoryMapping[selectedSubCategory];
//       const filtered = allProducts.filter(product => productTypes.includes(product.productType));
//       setFilteredProducts(filtered);
//     } else {
//       setFilteredProducts(allProducts); // No sub-category is selected, show all products
//     }
//   };

//   const handleSubCategoryChange = (subCategory) => {
//     setSelectedSubCategory(subCategory);
//   };

//   const currentPosts = filteredProducts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

//   return (
//     <React.Fragment>
//       <Box className="header-gradient">Men's Wear</Box>

//       {/* Displaying sub-categories as filter options */}
//       <Box display="flex" justifyContent="center" flexWrap="wrap" sx={{ my: 2 }}>
//         {Object.keys(subCategoryMapping).map(subCategory => (
//           <button
//             key={subCategory}
//             onClick={() => handleSubCategoryChange(subCategory)}
//             className={`sub-category-button ${selectedSubCategory === subCategory ? "active" : ""}`}
//           >
//             {subCategory}
//           </button>
//         ))}
//       </Box>

//       <div className="products-container">
//         {currentPosts.map(product => (
//           <IndividualProductFilter
//             key={product.ID}
//             individualProductFilter={product}
//             handleClick={() => {
//               setProductDetails(product);
//               setDialogOpen(true);
//             }}
//           />
//         ))}
//       </div>

//       <Pagination
//         totalPosts={filteredProducts.length}
//         postsPerPage={postsPerPage}
//         setCurrentPage={setCurrentPage}
//         currentPage={currentPage}
//       />

//       <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
//         <DialogTitle>Product Details</DialogTitle>
//         <DialogContent>
//           {productDetails && <div>{productDetails.name}</div>}
//         </DialogContent>
//       </Dialog>
//     </React.Fragment>
//   );
// };

// export default Mens;

import React, { useEffect, useState } from "react";
import { fs } from "../firebase";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import IndividualProductFilter from "./IndividualProductFilter";
import Pagination from "./Pagination";
import "./Mens.css";

const categoryToProductTypeMapping = {
  TopWear: ["TOPS", "SHIRT", "JACKET", "SHAKET", "DRESS", "JUMPSUIT", "ROMPER"],
  BottomWear: ["JUMPSUIT", "TROUSER", "SHORTS", "SET", "JOGGER"],
  LoungeWear: ["LONGEWEAR"],
  DenimWear: ["TOPS", "SHIRTS", "OUTERWEAR", "JACKET", "SHAKET", "DRESS", "JUMPSUIT", "ROMPER", "TROUSER", "SHORTS", "LONGEWEAR", "SET", "JOGGER" ],
};

const Mens = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  // Fetch all products from Firestore
  useEffect(() => {
    const getProducts = async () => {
      const snapshot = await fs.collection("AparellData").get();
      const productsArray = snapshot.docs.map(doc => ({ ID: doc.id, ...doc.data() }));
      setAllProducts(productsArray);
      setFilteredProducts(productsArray);
    };
    getProducts();
  }, []);

  // Filter products based on the selected product type
  useEffect(() => {
    const filtered = selectedProductType
      ? allProducts.filter(product => product.productType === selectedProductType)
      : allProducts;
    setFilteredProducts(filtered);
  }, [selectedProductType, allProducts]);

  // Handle changing the selected product type
  const handleProductTypeChange = (type) => {
    setSelectedProductType(prevType => prevType === type ? '' : type);
  };

  const currentPosts = filteredProducts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

  return (
    <React.Fragment>
      <Box className="header-gradient">Men's Wear</Box>

      {/* Display categories as buttons */}
      <Box display="flex" justifyContent="space-between" marginX="auto" sx={{ maxWidth: 720, mx: "auto", p: 2 }}>
        {Object.keys(categoryToProductTypeMapping).map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`category-button ${selectedCategory === category ? "selected" : ""}`}
          >
            {category}
          </button>
        ))}
      </Box>

      {/* Display product types as filter options if a category is selected */}
      {selectedCategory && (
        <Box display="flex" justifyContent="center" flexWrap="wrap" sx={{ my: 2 }}>
          {categoryToProductTypeMapping[selectedCategory].map(type => (
            <button
              key={type}
              onClick={() => handleProductTypeChange(type)}
              className={`product-type-button ${selectedProductType === type ? "active" : ""}`}
              style={{ margin: '8px' }}
            >
              {type}
            </button>
          ))}
        </Box>
      )}

      <div className="products-container">
        {currentPosts.map(product => (
          <IndividualProductFilter
            key={product.ID}
            individualProductFilter={product}
            handleClick={() => {
              setProductDetails(product);
              setDialogOpen(true);
            }}
          />
        ))}
      </div>

      <Pagination
        totalPosts={filteredProducts.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />

      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          {productDetails && <div>{productDetails.name}</div>}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Mens;

