import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyAcZ0JfF2S0Ikv6HJPHHnaz_nGCMnMiC5Q",
  authDomain: "sampledesign-16573.firebaseapp.com",
  projectId: "sampledesign-16573",
  storageBucket: "sampledesign-16573.appspot.com",
  messagingSenderId: "725300368350",
  appId: "1:725300368350:web:4d548c7397d3beca71034f",
  measurementId: "G-Y2M4LSKEV5",
};

firebase.initializeApp(firebaseConfig);
const fs = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
export const db = getFirestore();
export { auth, fs, storage };
