import React,{useState, useEffect}from 'react'
import { fs,storage } from '../firebase';
import {Typography, Box, Stack, Dialog } from '@mui/material'
import {InputField} from './product/Components/InputField'
import {ProductDropDown} from './product/Components/ProductDropDown'
import PrintSticker from './PrintSticker';
import StickerDividend from './StickerDividend';
import StickerIndianDesign from './StickerIndiandesign';
import StickerIndianDesignWithoutPrice from './StickerIndianDesignWithoutPrice';
import {FlatButton} from './commonComponents/FlatButton'
import { useParams, useNavigate  } from 'react-router-dom';


export const Addfabric = (props) => {
  const history= useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {productDetails1, isFabricEdited} = props
  const [imagesList,setImagesList]=useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [idPrefix,setIdPrefix]=useState('IDC-FAB')
  const [uniqueRecordId,setUniqueRecordId]=useState('')
  const [fabricsup,setFabricsup]=useState('');
  const [materialcd,setMaterial]=useState('');
  const [count,setCount]=useState('');
  const [construction,setConstruction]=useState('');
  const [composition,setComposition]=useState('');
  const   [gsm,setGsm]=useState('');
  const [cuttable,setCutablle]=useState('');
  const [finish,setFinish]=useState('');
  const [weave,setWeave]=useState('');
  const [fabrictype,setFabrictype]=useState('');
  const [price,setPrice]=useState('');
  const [moq,setMoq]=useState('');
  const [leadtime,setLeadtime]=useState('');
  const [submitted,setSubmitted]=useState('');
  const [fpt,setFpt]=useState('');
  const [remarks,setRemarks]=useState('');
  const [selectedDropdownValue,setSelectedDropdownValue]=useState(0);
  const [image,setImage]=useState(null);
  const [stickersList,setStickersList]=useState([])
  const [imageError,setImageError]=useState('');
  const [successMsg,setSuccesMsg]=useState('');
  const [uploadError,setUploadError]=useState('');
  const [isRecordEdited,setIsRecordEdited]=useState(false);
  const [openPrintSticker,setOpenPrintSticker]=useState(false);
  const types=['image/jpeg','image/jpg','image/png','image/svg','image/pdf'];

  useEffect(()=>{
    if(productDetails1 !== null && productDetails1 !== undefined){
      const {fabricsup, materialcd, count, construction, composition, gsm, finish,
        cuttable, weave, price, remarks,fabrictype,moq,submitted,fpt,leadtime,uniqueRecordId,imageUrls}=productDetails1

        setIsRecordEdited(true)
        setUniqueRecordId(uniqueRecordId) 
        setFabricsup(fabricsup)
        setMaterial(materialcd)
        setCount(count)
        setConstruction(construction)
        setComposition(composition)
        setGsm(gsm)
        setFinish(finish)
        setCutablle(cuttable)
        setWeave(weave)
        setFabrictype(fabrictype)
        setPrice(price)
        setRemarks(remarks)
        setMoq(moq)
        setLeadtime(leadtime)
       setSubmitted(submitted)
       setFpt(fpt)
       setImageUrls(imageUrls)
    }
  },[productDetails1])

     useEffect(()=>{
   
       if(imageUrls.length > 0 && imagesList.length > 0 && imageUrls.length === imagesList.length){
       handleAddProducts()
       }
   

    }, [imageUrls])

      function generateCode() {
       const length = 10;
       const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
       let code = '';
      for (let i = 0; i < length; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
     }
    
      return code;
    
    }
        useEffect(() => {
          
            if(productDetails1 !== null && productDetails1 !== undefined){
              if(isFabricEdited === true){  
                 const {uniqueRecordId}=productDetails1
                 setUniqueRecordId(uniqueRecordId)
              }
        }else{
          const newCode = generateCode();
          setUniqueRecordId(`${idPrefix}-${newCode}`);
      }
        
   }, []);
  

  const handleProductImg=(e)=>{
    const imageFiles = []
    const imageTypeStatus = false
    Object.entries(e.target.files).map(([key, value])=>{
      if(value&&types.includes(value.type)){
        imageFiles.push(value)
      }else{
        imageTypeStatus = true
      }
    })

    if(imageTypeStatus){
      setImagesList([]);
      setImageError('plz select valid type')
     return
    }
  
    if (imageFiles.length > 0){
      setImagesList(imageFiles)
    }
}
  const handleAddProducts=async()=>{
      fs.collection('Products1').doc(uniqueRecordId).set({
      uniqueRecordId,
        fabricsup,
        materialcd,
        count,
        construction,
        composition,
        gsm,
        cuttable,
        finish,
        weave,
        fabrictype,
        price,
        moq,
        leadtime,
        submitted,
        fpt,
        remarks,
        imageUrls
        
      }).then(()=>{
     alert('Fabric Data Added Succesfully')
         setFabricsup('');
         setMaterial('');
         setCount('');
         setConstruction('');
         setComposition('');
         setGsm('');
         setCutablle('');
         setFinish('');
         setWeave('');
         setFabrictype('');      
         setPrice('');
         setMoq('');
         setLeadtime('');
         setSubmitted('');
         setFpt('');
         setRemarks('');
         document.getElementById('file').value='';
         setImageError('');
         setUploadError('');
         setTimeout(()=>{
           setSuccesMsg('');
          
        },[])
        
      })
    }
  
  
  // const getPrintStickersList =async()=>{
  //   const value=await fs.collection('StickersList').get();
    
  //   const productsArray=[];
  //   for(var snap of value.docs){
  //     var data=snap.data();
  //     setStickersList(data.Stickers)
  //   }
  // }
  // useEffect(()=>{
  //   getPrintStickersList();
  // },[])
  /**Handle  Stickers list */
// const handleStickersList = ()=>{
//   return stickersList.map((element)=>{
//     return element
//   })
// }

/**Handle Stickers change */
const handleStickersChange = (Stickers)=>{
  setStickersList(Stickers)
}

/**Handle fabric supplier change */
const handlesupplierChange = (supplier)=>{
  setFabricsup(supplier)
}
/**Handle fabric count change */
const handlecountChange = (count)=>{
  setCount(count)
}
/**Handle fabric materialcd change */
const handlematerialcdChange = (materialcd)=>{
  setMaterial(materialcd)
}
/**Handle fabric Construction change */
const handleConstructionChange = (Construction)=>{
  setConstruction(Construction)
}
/**Handle fabric Composition change */
const handleCompositionChange = (Composition)=>{
  setComposition(Composition)
}
/**Handle fabric Gsm change */
const handleGsmChange = (Gsm)=>{
  setGsm(Gsm)
}
/**Handle fabric Cuttable-width change */
const handleCuttablewidthChange = (Cuttablewidth)=>{
  setCutablle(Cuttablewidth)
}
/**Handle fabric Finish change */
const handleFinishChange = (Finish)=>{
  setFinish(Finish)
}

/**Handle fabric Weave change */
const handleWeaveChange = (Weave)=>{
  setWeave(Weave)
}

/**Handle fabric Fabric-Type change */
const handleFabricTypeChange = (FabricType)=>{
  setFabrictype(FabricType)
}
/**Handle fabric Price change */
const handlePriceChange = (Price)=>{
  setPrice(Price)
}
/**Handle fabric MOQ change */
const handleMOQChange = (MOQ)=>{
  setMoq(MOQ)
}
/**Handle fabric LeadTime change */
const handleLeadTimeChange = (LeadTime)=>{
  setLeadtime(LeadTime)
}
/**Handle fabric Submitted to change */
const handleSubmittedtoChange = (Submittedto)=>{
  setSubmitted(Submittedto)
}
/**Handle fabric Fpt to change */
const handleFptChange = (Fpt)=>{
  setFpt(Fpt)
}
/**Handle fabric Remarks to change */
const handleRemarksChange = (Remarks)=>{
  setRemarks(Remarks)
}
/**Upload images and fetch stored image url's */
const FabhandleUploadimagesToFireStore = ()=>{

    if(imagesList.length > 0){
    const urls = []
    Promise.all(imagesList.map(async (image, index)=>{
     const urlResult = await uploadImageAsPromise(image, index)
     urls.push(urlResult)
     if(urls.length === imagesList.length){
      setImageUrls(urls)
         }
       }))
  } else if (fabricsup===''|| materialcd===''|| count===''|| construction===''|| composition===''|| gsm==='' || finish===''
  ||cuttable==='' || weave===''|| price===''|| remarks===''|| fabrictype===''|| moq===''|| submitted===''|| fpt===''|| leadtime==='')
  {  alert('Please Insert the Misssing Fields')}

    if(isRecordEdited === true){
      handleAddProducts()
      alert('data Updated successfully')
      return
    } 
}
//Handle waiting to upload each file using promise
function uploadImageAsPromise (image, index) {
  return new Promise(function (resolve, reject) {
    const uploadTask= storage.ref(`product-images-Fabric/${image.name}-${index}`).put(image);
    uploadTask.on('state_changed',snapshot=>{
      const progess=(snapshot.bytesTransferred/snapshot.totalBytes)*100 
  },error=>reject(error.message),()=>{
   storage.ref('product-images-Fabric').child(`${image.name}-${index}`).getDownloadURL().then((url) => {
    resolve(url)
   })
   })
   
  });
}
const handlePrintSticker = ()=>{
  if(selectedDropdownValue===0){
    alert('Select Sticker')
  }
  else
   setDialogOpen(true)    
}
const handleDialogToClose = () => {
  setDialogOpen(false);
}

  return(
    <React.Fragment>
    
      <Dialog onClose={handleDialogToClose} open={dialogOpen} PaperProps={{
        sx:{
          width: '70%',
          height:'70%',
          maxWidth: '70%',
          overflowX: 'scroll',
          overflowY: 'scroll',
          scrollBehavior:'auto'
        }
      }}>
      {
        selectedDropdownValue === 1 
        ?<PrintSticker fabricSupplier= {fabricsup} materialCode= {materialcd}
      count= {count} construction= {construction}
      composition= {composition} GSM= {gsm}
      finish= {finish} cuttableWidth= {cuttable}
      weave= {weave} price= {price}
      remarks= {remarks}> </PrintSticker>

      :selectedDropdownValue ===  2
      ?<StickerDividend fabricSupplier= {fabricsup} materialCode= {materialcd}
      count= {count} construction= {construction}
      composition= {composition} GSM= {gsm}
      finish= {finish} cuttableWidth= {cuttable}
      weave= {weave} price= {price}
      remarks= {remarks}
      ></StickerDividend>
      :selectedDropdownValue === 3
        ?<StickerIndianDesign fabricSupplier= {fabricsup} materialCode= {materialcd}
        count= {count} construction= {construction}
        composition= {composition} GSM= {gsm}
        finish= {finish} cuttableWidth= {cuttable}
        weave= {weave} price= {price}
        remarks= {remarks}
        ></StickerIndianDesign>
        :selectedDropdownValue === 4
        ?<StickerIndianDesignWithoutPrice fabricSupplier= {fabricsup} materialCode= {materialcd}
        count= {count} construction= {construction}
        composition= {composition} GSM= {gsm}
        finish= {finish} cuttableWidth= {cuttable}
        weave= {weave} price= {price}
        remarks= {remarks}
        ></StickerIndianDesignWithoutPrice>
        : null
}
</Dialog>
      <Box>
  <Typography variant='h6' sx={{color: 'GrayText', paddingLeft: 40, paddingTop: 2}}>ADD FABRIC</Typography>
     <Box sx={{paddingLeft: 6}}>
    <Typography variant='body2' sx={{fontFamily:'Inter'}}>{uniqueRecordId}</Typography>
    </Box> 
   <Box sx={{paddingLeft: 6, paddingTop: 5}}>
   <Stack spacing={5} direction="row">
   <InputField label={'Fabric Material(T1)'} value={fabricsup} handleChange={handlesupplierChange} />
   <InputField label={'T2 Material Code'} value={materialcd} handleChange={handlematerialcdChange} />
   <InputField label={'Count'} value={count} handleChange={handlecountChange} />
   </Stack>
   <Stack spacing={5} direction="row">
   <InputField label={'Construction'} value={construction} handleChange={handleConstructionChange} />
   <InputField label={'Composition'} value={composition} handleChange={handleCompositionChange} />
   <InputField label={'Gsm'} value={gsm} handleChange={handleGsmChange} />


   </Stack>
   <Stack spacing={5} direction="row">
   <InputField label={'Cuttable-width'} value={cuttable} handleChange={handleCuttablewidthChange} />
   <InputField label={'Finish'} value={finish} handleChange={handleFinishChange} />
   <InputField label={'Weave'} value={weave} handleChange={handleWeaveChange} />

   </Stack>
   <Stack spacing={5} direction="row">
   <InputField label={'Fabric-Type'} value={fabrictype} handleChange={handleFabricTypeChange} />
   <InputField label={'Price'} value={price} handleChange={handlePriceChange} />
   <InputField label={'MOQ'} value={moq} handleChange={handleMOQChange} />

   </Stack>
   <Stack spacing={5} direction="row">
   <InputField label={'LeadTime'} value={leadtime} handleChange={handleLeadTimeChange} />
   <InputField label={'Submitted to'} value={submitted} handleChange={handleSubmittedtoChange} />
   <InputField label={'FPT'} value={fpt} handleChange={handleFptChange} />

   </Stack>
   <Stack spacing={5} sx={{ paddingTop: 2 }}  direction="row">
   <InputField label={'Remarks'} value={remarks} handleChange={handleRemarksChange} />
   
    <input type='file' id='file' multiple  
      onChange={handleProductImg} />

      <Box sx={{paddingTop: 1 , paddingLeft:9}}>
    <Typography variant='body2' sx={{fontFamily:'serif'}}>
    <select className='' id="selectBox"  value={selectedDropdownValue} onChange={(e)=>setSelectedDropdownValue(Number(e.target.value))}>
                <option value={0} >---Select---</option>
                <option value={1} >H&M</option>
                <option value={2} >DIVIDED</option>
                <option value={3} >INDIAN DESIGN with Price</option>
                <option value={4} >INDIAN DESIGN without Price</option></select> 

        <button className='ml-3 ' onClick={handlePrintSticker}>Print</button>
    </Typography>
    </Box>
      </Stack> 
       <Box display="flex"
        justifyContent="center"
        alignItems="center" sx={{ paddingTop: 4, paddingBottom: 4 }}>
        <FlatButton label='SUBMIT' onClick={FabhandleUploadimagesToFireStore}/>
      </Box>
     </Box> </Box>
    </React.Fragment>
  ) 
}
export default Addfabric