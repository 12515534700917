import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { faDownload, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import "./ProductCard.css";
import ProductDetails from "./ProductDetails";

const ProductCard = ({ item }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { productId } = useParams();

  // Function to handle image download
  const handleDownload = async () => {
    const imageSrc = item.imageUrls?.[0] || 'path/to/your/default-image.jpg';

    fetch(imageSrc)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(imageBlob => {
        const fileURL = URL.createObjectURL(imageBlob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = item.stylename ? item.stylename.replace(/\s+/g, '_') : 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      })
      .catch(error => {
        console.error('Failed to download the image:', error);
        alert('Failed to download the image. See console for details.');
      });
  };

  // Function to handle sharing
  const handleShare = async () => {
    if (navigator.share) {
      try {
        const productUrl = `${window.location.origin}/products/${item.uniqueId}?details=open`;
        await navigator.share({
          title: item.stylename || 'Check out this product!',
          url: productUrl,
          text: 'Take a look at this amazing product!'
        });
      } catch (error) {
        console.error("Error sharing", error);
        alert("Failed to share. See console for details.");
      }
    } else {
      console.log("Web Share API is not supported in this browser.");
      // Optionally, copy to clipboard as a fallback
      navigator.clipboard.writeText(`${window.location.origin}/products/${item.uniqueId}?details=open`)
        .then(() => alert('Link copied to clipboard!'))
        .catch(err => console.error('Failed to copy link: ', err));
    }
  };

  // Function to open the dialog
  const handleOpenDialog = () => {
    if (!isDialogOpen) {
      setIsDialogOpen(true);
    }
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="product-card" onClick={handleOpenDialog}>
      <h2>{item.stylecode || 'No Style Name'}</h2>
      {!imageLoaded && <div className="image-placeholder">Loading...</div>}
      <img
        src={item.imageUrls?.[0] || 'path/to/your/default-image.jpg'}
        alt={item.stylename || 'Product image'}
        style={{ display: imageLoaded ? 'block' : 'none' }}
        onLoad={() => setImageLoaded(true)}
      />
      {/* <p>Category: {item.category || 'No Category'}</p>
      <p>Type: {item.producttype || 'No Product Type'}</p> */}




      {/* <div className="actions" onClick={(e) => e.stopPropagation()}>
        <button onClick={(e) => { e.stopPropagation(); handleDownload(); }}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
        <button onClick={(e) => { e.stopPropagation(); handleShare(); }}>
          <FontAwesomeIcon icon={faShareAlt} />
        </button>
      </div> */}
      {/* Dialog for product details */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "79%",
          maxHeight: "100%",
          overflowX: "scroll",
          overflowY: "scroll",
          scrollBehavior: "auto",
        },
      }}>
        <DialogTitle id="dialog-title" sx={{ textAlign: 'center' }}>{item.stylename || 'No Style Name'}</DialogTitle>
        <DialogContent>
          <ProductDetails productDetails={item} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductCard;