import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import { Products2 } from './Products2';
import { fs,storage } from '../firebase';

export const MerchantGallery = () => {
    const [products2,setProducts2]=useState([]);
    const getProducts=async()=>{
        const products2=await fs.collection('Products2').get();
        const productsArray=[];
        for(var snap of products2.docs){
          var data=snap.data();
          data.ID=snap.id;
          productsArray.push({
            ...data
          })
          if(productsArray.length===products2.docs.length){
            setProducts2(productsArray);
    
          }
        }
      }
    
      useEffect(()=>{
        getProducts();
      },[])
    

  return (
    <>
    <div className='flex flex-row pt-1'> 
    <Link to="/Home">
      <button className='ml-1 bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded'>Apparel</button>
      </Link>
      <Link to="/FabricGallery" className='ml-1'>
      <button className='bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded'>Fabric</button>
      </Link>
      <Link to="/MerchantGallery" className='ml-1'>
      <button className='bg-blue-500 hover:bg-blue-700 text-white  py-1 px-4 rounded'>Merchant</button>
      </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {/* <input type='text' placeholder='search...' /> */}
</div>
    <div className='products-container'>

    {products2.length> 0 && (
      
   <Products2 products2={products2}/>
    )} 
    
    </div>
   
      
    {products2.length < 1 && (
      <div>please wait.....</div>
    )}
      </>  
  )
}
  export default MerchantGallery;