import React from 'react'

export const IndividualProduct1 = ({individualProduct1, handleClick1}) => {
  return (
<div className='products-container '>
  <div className='product-card object-cover hover:scale-105 transition-scale duration-200 ease-in'>
    <div className='product-img'>
    <img src={individualProduct1.imageUrls[0]} onClick={()=>handleClick1(individualProduct1)} alt="product-img" /></div>
        <div className='product-name'>{individualProduct1.fabricsup}</div>
         <p className='product-price'>${individualProduct1.price}</p>
    </div>
    </div>
  )
}

export default IndividualProduct1;