import React, {useState} from 'react'
import { Form, Link } from 'react-router-dom'


export const IndividualProduct = ({individualProduct, handleClick}) => {
  const [imageUrl, setImageUrl] = useState(null);

  return (
<div className='products-container '>
  <div className='product-card object-cover hover:scale-105 transition-scale duration-200 ease-in'>
    <div className='product-img'>
    <img src={individualProduct.imageUrls[0]} onClick={()=>handleClick(individualProduct)} /></div>
        <div className='product-name'>{individualProduct.stylename}</div>
         <p className='product-price'>${individualProduct.fobcost}</p>
    </div>
 </div>

  )
}
export default IndividualProduct;
